import { Box, Button, Container, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { makeStyles, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import './Constants/style.css'
import CachedIcon from '@material-ui/icons/Cached';
import Avatar from './Avatar';
import API from './Constants/API';
import DbConnection from './Constants/DbConnection';
import { theme } from './Constants/CustomTheme'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

var DataFile = require('./Constants/data');
var videoPath = API.Videoserveraddress + "/video/"

const db = new DbConnection();

const useStyles = theme => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    },
    
    button: {
        margin: 2,
        textAlign: "left",
    },
   

});

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            catList: ['School', 'Colors', 'Days', 'Months', 'Fruits', 'Health', 'States'],
            lang: 'e',

            hiw: '',
            pbw: '',
            engw: '',
            selected: -1,
            value: null,
            answer: false,
            currentIndex: -1,
            wid: -1,
            cstyles: [null, null, null, null],
            video: "./video/Although_it_was_raining.mp4",
            optionHeader: "Please Select Correct Option",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            correctOption: "",
            correctIndex: -1,
            player: null,
            videoPaused: true,

        };
    }


    componentDidMount() {
        var lang = this.props.match.params.lang;
        var type = this.props.match.params.type;
        if(type=="w"){
            DataFile = require('./Constants/wordData');
            videoPath = API.Videoserveraddress + "/wvideo/"

        }
        this.setState({
            lang: lang,
        });
        if (lang === "e") {

            this.setState(
                {
                    optionHeader: "Please Select Correct Option",
                    buttonDisable: true,
                    buttonTitle: "Next",
                }
            );
        }
        else if (lang === "h") {
            this.setState(
                {
                    optionHeader: "कृपया सही विकल्प चुनें",
                    buttonDisable: true,
                    buttonTitle: "आगे",
                }
            );

        }
        else if (lang === "p") {
            this.setState(
                {
                    optionHeader: "ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਵਿਕਲਪ ਦੀ ਚੋਣ ਕਰੋ",
                    buttonDisable: true,
                    buttonTitle: "ਅੱਗੇ",
                }
            );
        }
        //this.loadData(lang);
       // document.getElementById("videoplayer").load();
       this.nextQuestion();
    }

    nextQuestion = () => {
        this.loadData(this.state.lang);
        document.getElementById("videoplayer").load();
    }
    getRow = () => {
        var index = Math.floor(Math.random() * DataFile.data.length);
        //  console.log( index);
        var ob = DataFile.data[index];
        return ob;
    }
    loadData = (lang) => {
        this.setState({
            videoPaused: true,
            buttonDisable: true,
        })
        var correctRow = this.getRow();

        const style = {
            backgroundColor: '#2c3e50',

        };
        var arr = [];
        arr[0] = style;
        arr[1] = style;
        arr[2] = style;
        arr[3] = style;

        var correctIndex = Math.floor(Math.random() * 4);
        //    console.log(correctIndex);


        var options = [];
        if (lang === "e") {

            options[correctIndex] = correctRow.EWord;
        }
        else if (lang === "h") {
            options[correctIndex] = correctRow.HWord;

        }
        else if (lang === "p") {
            options[correctIndex] = correctRow.PWord;
        }



        for (var i = 0; i < 4; i++) {
            if (i == correctIndex) {
                continue;
            }
            else {
                console.log("loop : " + i);

                var nextRow = this.getRow();
                // console.log(nextRow.WID + nextRow.PWord);
                // console.log(options.includes(nextRow.PWord));
                // console.log(options);


                console.log(options)
                if (lang === "e") {
                    // options[i] = nextRow.EWord;

                    if (!(options.includes(nextRow.EWord))) {
                        options[i] = nextRow.EWord;

                    }
                    else {
                        i--;
                    }
                }
                else if (lang === "h") {
                    if (!(options.includes(nextRow.HWord))) {
                        options[i] = nextRow.HWord;
                    }
                    else {
                        i--;
                    }

                }
                else if (lang === "p") {

                    if (!(options.includes(nextRow.PWord))) {
                        options[i] = nextRow.PWord;
                    }
                    else {
                        i--;
                    }

                }


            }
        }

        var correctWord = "";

        if (lang === "e") {

            correctWord = correctRow.EWord;
        }
        else if (lang === "h") {
            correctWord = correctRow.HWord;
        }
        else if (lang === "p") {
            correctWord = correctRow.PWord;

        }



        this.setState({

            //video:,
            wid: correctRow.WID,
            video: correctRow.VideoPath,
            option1: options[0],
            option2: options[1],
            option3: options[2],
            option4: options[3],
            correctOption: correctWord,
            correctIndex: correctIndex,
            answer: false,
            cstyles: [null, null, null, null],
            videoPaused: false,
        })



        console.log("result " + this.state.wid);
    }


   

    handleClickMe = (index) => {
        var arr = this.state.cstyles;
        if (index === this.state.correctIndex) {
            const style = {
                backgroundColor: "green",
                color: '#fff',
            };
            arr[index] = style;

            this.setState({
                answer: true,
                cstyles: arr,

            })
            //    console.log(this.state);
        }
        else {
            const style = {
                backgroundColor: "red",
                color: '#fff',

            };
            const style2 = {
                backgroundColor: "green",
                color: '#fff',
            };
            arr[index] = style;
            arr[this.state.correctIndex] = style2;

            this.setState({
                cstyles: arr,
                answer: false
            })
        }
        this.setState({
            currentIndex: index,
            buttonDisable: false,
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid container spacing={5} justify={"center"}>
                        {/* <Grid item md={3}>

                            <div className="sidebar">


                                <div className="sidebar-box">
                                    <div className="sidebar-box-inner">
                                        <h3 className="sidebar-title">Categories</h3>
                                        <ul className="sidebar-categories">
                                            <>  {this.state.catList.map((row, index) => (
                                                <li key={index} onClick={() => {  }}>{row}</li>
                                            ))}
                                            </>
                                        </ul>
                                    </div>
                                </div>

                            </div>


                        </Grid> */}
                        <Grid item md={9}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>{this.state.optionHeader}</p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="contained" color="primary" onClick={() => { this.nextQuestion()}}> Next Question </Button> <a href="#gried-Box" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-Box">
                                    <Grid container spacing={3}>
                                        <Grid item md={6} >
                                            <Paper className={classes.paper}>


                                                <Box margin={2}>
                                                    <Button style={this.state.cstyles[0]} onClick={() => { this.handleClickMe(0) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                        {this.state.option1}
                                                    </Button>
                                                </Box>
                                                <Box margin={2}>
                                                    <Button style={this.state.cstyles[1]} onClick={() => { this.handleClickMe(1) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                        {this.state.option2}

                                                    </Button>
                                                </Box>
                                                <Box margin={2}>
                                                    <Button style={this.state.cstyles[2]} onClick={() => { this.handleClickMe(2) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                        {this.state.option3}

                                                    </Button>
                                                </Box>
                                                <Box margin={2}>
                                                    <Button style={this.state.cstyles[3]} onClick={() => { this.handleClickMe(3) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                        {this.state.option4}

                                                    </Button>
                                                </Box>

                                            </Paper>
                                        </Grid>
                                        <Grid item md={6} >
                                            <Paper className={classes.paper}>
                                                <video 
                                                id="videoplayer" 
                                                width="100%" 
                                                height="240" 
                                                autoPlay 
                                                controlsList="nodownload" 
                                                loop 
                                                controls
                                                poster={'https://cdn.dribbble.com/users/2325280/screenshots/5318572/loading.gif'}
                                                ref={component => (this.state.player = component)}
                                                >
                                                    <source src={videoPath + this.state.video} type="video/mp4" />
                                                </video>

                                            </Paper>

                                        </Grid>
                                    </Grid>



                                </div>

                            </div>

                        </Grid>


                    </Grid>
                </Container>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(Search);
