import { Box, Button, Container, Fade, Grid, Paper, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import './Constants/style.css'
import CachedIcon from '@material-ui/icons/Cached';
import { theme } from './Constants/CustomTheme';
import API from './Constants/API';
import clsx from 'clsx';
import Colors from './Constants/Colors';
import { withSnackbar } from 'notistack';

const DataFile = require('./Constants/WislWordDataset');
const DataFileChar = require('./Constants/WislDataset');


const useStyles = () => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    },
    button: {
        margin: 2,
        textAlign: "left",
    },
    textBlock: {
        height: "100%",
        background: '#fdc800',
        justifyContent: "center",
        display: "flex",
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: Colors.themegrey,

        '&:hover': {
            borderColor: Colors.themeblue,
            background: Colors.themeyellow,
        }
    },
    selected: {
        borderColor: '#002147',
    },
    correct: {
        borderColor: '#008c3f',
    },
    text: {
        margin: "auto",
    },
    imgBlock: {
        height: "auto",
        width:"100%",
        maxWidth: "120px",
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: Colors.tomatoRed,
        '&:hover': {
            borderColor: Colors.themeblue,

        }
    }
});
class WordLetterSignMatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'e',
            optionHeader: '',
            buttonTitle: '',
            answer: false,


            correct: false,
            boxColor: '#fff',
            fade: true,
            output:"",
            options: [],
            answers: [],
            correctWord: '',

        };
    }
    componentDidMount() {
        var lang = this.props.match.params.lang;
        this.setState({
            lang: lang,
        })
        this.load(lang);

    }


    load = async (lang) => {
        if (lang === "e") {

            this.setState(
                {
                    optionHeader: "Please Set The Correct Squence of Sign Images to Make a Word",
                    buttonTitle: "Next",
                    list: await DataFile.english.list,
                    listChar: await DataFileChar.english.list,
                }
            );
        }
        else if (lang === "h") {
            this.setState(
                {
                    optionHeader: "कृपया सही विकल्प चुनें",
                    buttonTitle: "आगे",
                    list: await DataFile.hindi.list,
                    listChar: await DataFileChar.hindi.list,

                }
            );

        }
        else if (lang === "p") {
            this.setState(
                {
                    optionHeader: "ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਵਿਕਲਪ ਦੀ ਚੋਣ ਕਰੋ",
                    buttonTitle: "ਅੱਗੇ",
                    list: await DataFile.punjabi.list,
                    listChar: await DataFileChar.punjabi.list,

                }
            );
        }

        this.loadData(lang);
    }
    loadData = () => {

        if (this.state.list.length === 0) {
            console.log("list empty");

            return;
        }

        var word = this.getRow();
        var options = [];
        var answers = [];
        var chArray = word.split("");
        if (chArray.length > 0) {
            console.log(word);
            console.log(chArray);
            var chArrayShuffled = this.shuffleArray(chArray);
            console.log(chArrayShuffled);
            for (var i = 0; i < chArrayShuffled.length; i++) {
                var current = this.findLetterData(chArrayShuffled[i]);
                options.push(current);
                answers.push({ ImgPath: 'missing.png', Text: '-' });

            }


            console.log(options);

            this.setState({
                options: options,
                correctWord: word,
                answers: answers,
                output:"",

            });

            return;

        }


    }
    findLetterData = (params) => {
        return this.state.listChar.find(element => element.Text == params);
    }

    shuffleArray = (arr) => {
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
    }
    getRow = () => {
        var index = Math.floor(Math.random() * this.state.list.length);
        //  console.log( index);
        var ob = this.state.list[index];
        return ob;

    }

    handleClickOption = (currentItem) => {
        this.setState({
            previousItem: currentItem,
        });
    }
    

    handleClickAnswer = (index) => {
        console.log(index);
        if (this.state.previousItem === null) {

            this.props.enqueueSnackbar('Please select Sign Image from the above #list1', {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return;
        }
        var answers = this.state.answers;
        answers[index] = this.state.previousItem;
        this.setState({
            answers:answers,
            previousItem:null,
        })
        console.log(answers);

        
    }

    validateAnswer = () => {
        var answers = this.state.answers;
        var word = answers.map(x => x.Text).join("");
        this.setState({
            output:word,
        });
        if(word === this.state.correctWord){
            this.props.enqueueSnackbar('Great, Correct Word Found. Click on The Next Button' , { 
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });

        }
        else
        {
            this.props.enqueueSnackbar('In-correct Match, Please Try Again or Click on Hint' , { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid container justify="center">
                        <Grid item md={8}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>{this.state.optionHeader} </p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="outlined" onClick={() => { this.loadData() }}> Next Question </Button> <a href="#gried-view" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-view">


                                    <Paper className={classes.paper} >

                                        <Box paddingTop={4} paddingBottom={2}>
                                            <Grid container justify="center" spacing={2} >

                                                <>
                                                    {this.state.options.map((row, index) => (
                                                        <Fade in={true} timeout={index * 500} key={index}>
                                                            <Grid item xs={2}>


                                                                <Box justifyItems={"center"}>

                                                                    <img src={API.Imgerveraddress + row.ImgPath} className={classes.imgBlock} alt={row.Text} onClick={() => { this.handleClickOption(row) }} />
                                                                </Box>
                                                            </Grid>
                                                        </Fade>

                                                    ))}
                                                </>
                                            </Grid>

                                        </Box>
                                       
                                        <Grid container justify="center" >
                                        <Box padding={1} style={{width:'100%', maxWidth:'300px', height:'50px', background:Colors.themeblue, textAlign:"center", color:'#fff'}} >

                                            <Typography variant="h4" color={"inherit"}>{this.state.output}</Typography>
                                        </Box>

</Grid>

                                        <Box paddingTop={2} paddingBottom={5}>
                                            <Grid container justify="center" spacing={2} >

                                                <>
                                                    {this.state.answers.map((row, index) => (
                                                        <Fade in={true} timeout={index * 500} key={index} >

                                                            <Grid item xs={2}>

                                                                <Box justifyItems={"center"}>

                                                                    <img src={API.Imgerveraddress + row.ImgPath} className={classes.imgBlock} alt={row.Text} onClick={() => { this.handleClickAnswer(index) }} />
                                                                </Box>
                                                            </Grid>
                                                        </Fade>

                                                    ))}
                                                </>
                                            </Grid>

                                        </Box>
                                        <Box >
                                        <Grid container justify="center" spacing={2} >
                                            <Button color="primary" variant="contained" onClick={()=>{this.validateAnswer()}}>Submit</Button>
                                        </Grid>
                                        </Box>
                                    </Paper>




                                </div>

                            </div>

                        </Grid>


                    </Grid>
                </Container>

            </MuiThemeProvider>
        )
    }
}
export default withSnackbar(withStyles(useStyles)(WordLetterSignMatch));
