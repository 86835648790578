import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { Route, BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import Index from './MyApp/Index';
import QuizSentence from './MyApp/QuizSentence';
import QuizWord from './MyApp/QuizWord';
import SingleLetterSign from './MyApp/SingleLetterSign';
import MissingSeriesSign from './MyApp/MissingSeriesSign';
import MatrixMatch from './MyApp/MatrixMatch';
import MatrixMatchHide from './MyApp/MatrixMatchHide';
import { SnackbarProvider } from 'notistack';
import WordLetterSignMatch from './MyApp/WordLetterSignMatch';
import ISLKeyboard from './MyApp/ISLKeyboard';
import QuizLearn from './MyApp/QuizLearn';

const routing = (
  <SnackbarProvider maxSnack={1}>
  <Router>
    <div>
      <Route exact path="/" component={Index} />
      <Route exact path="/QuizSentence/:lang/:type?" component={QuizSentence} />
      <Route exact path="/QuizWord/:lang" component={QuizWord} />
      <Route exact path="/QuizLearn/:lang/:type?" component={QuizLearn} />
      <Route exact path="/SingleLetterSign/:lang" component={SingleLetterSign} />
      <Route exact path="/MissingSeriesSign/:lang" component={MissingSeriesSign} />
      <Route exact path="/MatrixMatch/:lang" component={MatrixMatch} />
      <Route exact path="/MatrixMatchHide/:lang" component={MatrixMatchHide} />
      <Route exact path="/WordLetterSignMatch/:lang" component={WordLetterSignMatch} />
      <Route exact path="/ISLKeyboard/:lang/:type?" component={ISLKeyboard} />
      {/* <Route exact path="/TextSentence" component={TextSentenceSign} />
      <Route exact path="/CMUSphinx" component={CMUSphinx} />
      <Route path="/search/:lang/:word?" render={(props) => <Search {...props} />} /> */}
    </div>
  </Router>
  </SnackbarProvider>

)

function getCookie(name) {
 
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length >= 2) return parts.pop().split(";").shift();

}
var role=getCookie('RoleType');
if( role==='Admin' || role ==='TextGuest'){
}
else
{

 window.location='https://learnisl.com/login.php';
 //window.location='http://localhost:3001/';


}



ReactDOM.render(
  routing,
  document.getElementById('root')
);

reportWebVitals();


