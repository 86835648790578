module.exports = {
    "english": {
      "list": [
        "IS", "IN", "AT", "ON", "TO", "HE", "US", "IF", "GO", "WE", "UP", "MY", "OX", "OF", "SO", "SHE", "INK", "PEN", "ZIP", "JAM", "BAT", "CAN", "DEN", "FOG", "OLD", "RAT", "SUN", "VAN", "WIN", "XEN", "YAK", "TREE", "LOVE", "BACK", "HALL", "LION", "CAGE", "BEND", "KICK", "LONG", "HIDE", "BOAT", "BOOK", "DUCK", "BLACK", "CATCH", "PLANT", "YACHT", "YOUTH", "FRONT"
      ]
    },
    "hindi": {
      "list": [
        "फल", "रस", "दस", "घर", "जल", "तर", "बल", "भर", "वन", "डर", "मन", "दम", "महल", "कमर", "अमर", "उछल", "जनम", "सफर", "जतन", "कलश", "कलम", "कमल", "सड़क", "मटर", "नमक", "कनक", "बटन", "समझ", "अमन", "शहद", "फसल", "नरम", "ग़ज़ल", "भजन", "पनघट", "बड़बड़", "शलगम", "परबत", "सरकस", "अदरक", "उपवन", "कसरत", "हरदम", "मखमल", "करवट", "तरकश", "हरकत", "धड़कन", "मतलब", "हलचल"
      ]
    },
    "punjabi": {
      "list": [
        "ਫ਼ਲ", "ਰਸ", "ਦਸ", "ਘਰ", "ਜਲ", "ਤਰ", "ਮਰ", "ਭਰ", "ਵਰ", "ਡਰ", "ਕਰ", "ਦਮ", "ਬਰਫ਼", "ਕਮਰ", "ਅਮਰ", "ਭਰਮ", "ਜਨਮ", "ਸ਼ਰਮ", "ਕਰਮ", "ਗਰਮ", "ਕਲਮ", "ਕਮਲ", "ਸੜਕ", "ਮਟਰ", "ਨਮਕ", "ਕਣਕ", "ਬਟਨ", "ਸ਼ਬਦ", "ਅਮਨ", "ਧਰਮ", "ਫ਼ਸਲ", "ਨਰਮ", "ਗਜ਼ਲ", "ਭਜਨ", "ਪਨਘਟ", "ਬੜਬੜ", "ਸ਼ਲਗਮ", "ਪਰਬਤ", "ਸਰਕਸ", "ਅਦਰਕ", "ਬਰਤਨ", "ਕਸਰਤ", "ਹਰਦਮ", "ਮਖਮਲ", "ਕਰਵਟ", "ਤਰਕਸ਼", "ਹਰਕਤ", "ਸ਼ਸਤਰ", "ਮਤਲਬ", "ਹਲਚਲ"
      ]
    }
  }