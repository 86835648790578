import { Box, Button, Container, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { makeStyles, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import './Constants/style.css'
import CachedIcon from '@material-ui/icons/Cached';
import Avatar from './Avatar';
import API from './Constants/API';
import DbConnection from './Constants/DbConnection';
import { theme } from './Constants/CustomTheme'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

var DataFile = require('./Constants/data');
var videoPath = API.Videoserveraddress + "/video/"
var textSize = 26;
const db = new DbConnection();

const useStyles = theme => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    },
    
    button: {
        margin: 2,
        textAlign: "left",
    },
   

});

class QuizLearn extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            catList: ['Learn', 'Test'],
            lang: 'e',

            hiw: '',
            pbw: '',
            engw: '',
            wid: -1,
            video: "./video/Although_it_was_raining.mp4",
            optionHeader:"Select Correct Option",
            correctWord: "",
            player: null,
            videoPaused: true,

        };
    }


    componentDidMount() {
        var lang = this.props.match.params.lang;
        var type = this.props.match.params.type;
        if(type=="w"){
            DataFile = require('./Constants/wordData');
            videoPath = API.Videoserveraddress + "/wvideo/"

        }
        
        this.setState({
            lang: lang,
        });
        this.loadData(lang);
        if (lang == "e") {

            this.setState(
                {
                    optionHeader: "Please press the next button for more learn",
                    buttonTitle: "Next",
                }
            );
        }
        else if (lang == "h") {
            this.setState(
                {
                    optionHeader: "अधिक सीखने के लिए अगला बटन दबाएं",
                    buttonTitle: "आगे",
                }
            );

        }
        else if (lang == "p") {
            this.setState(
                {
                    optionHeader: "ਕਿਰਪਾ ਕਰਕੇ ਹੋਰ ਸਿੱਖਣ ਲਈ ਅਗਲਾ ਬਟਨ ਦਬਾਓ",
                    buttonTitle: "ਅੱਗੇ",
                }
            );
        }
    }

    nextQuestion = () => {
        this.loadData(this.state.lang);
        document.getElementById("videoplayer").load();
    }
    getRow = () => {
        var index = Math.floor(Math.random() * DataFile.data.length);
        //  console.log( index);
        var ob = DataFile.data[index];
        return ob;
    }
    loadData = (lang) => {
        this.setState({
            videoPaused: true,
        })
        var correctRow = this.getRow();

      var correctWord="";

        if (lang === "e") {

            correctWord = correctRow.EWord;
        }
        else if (lang === "h") {
            correctWord = correctRow.HWord;
        }
        else if (lang === "p") {
            correctWord = correctRow.PWord;

        }



        this.setState({
            wid: correctRow.WID,
            video:correctRow.VideoPath,
            correctWord: correctWord,
            videoPaused: false,
        })



        console.log("result " + this.state.wid);
    }


   

    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid container spacing={5} justify={"center"}>
                        {/* <Grid item md={3}>

                            <div className="sidebar">


                                <div className="sidebar-box">
                                    <div className="sidebar-box-inner">
                                        <h3 className="sidebar-title">Categories</h3>
                                        <ul className="sidebar-categories">
                                            <>  {this.state.catList.map((row, index) => (
                                                <li key={index} onClick={() => {  }}>{row}</li>
                                            ))}
                                            </>
                                        </ul>
                                    </div>
                                </div>

                            </div>


                        </Grid>
                         */}
                        <Grid item md={9}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>{this.state.optionHeader}</p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="contained" color="primary" onClick={() => { this.nextQuestion()}}> Next Question </Button> <a href="#gried-Box" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-Box">
                                    <Grid container spacing={3}>
                                        <Grid item md={6} >
                                            <Paper className={classes.paper}>


                                                <Box margin={2} paddingY={5}>
                                                    <Typography  style={{padding:5, textAlign:"center", fontSize:textSize}}> 
                                                        {this.state.correctWord}
                                                        </Typography>
                                                </Box>
                                              

                                            </Paper>
                                        </Grid>
                                        <Grid item md={6} >
                                            <Paper className={classes.paper}>
                                                <video 
                                                id="videoplayer" 
                                                width="100%" 
                                                height="240" 
                                                autoPlay 
                                                loop 
                                                controlsList="nodownload"
                                                ref={component => (this.state.player = component)}
                                                poster={'https://cdn.dribbble.com/users/2325280/screenshots/5318572/loading.gif'}
                                                >
                                                    <source src={videoPath + this.state.video} type="video/mp4" />
                                                </video>

                                            </Paper>

                                        </Grid>
                                    </Grid>



                                </div>

                            </div>

                        </Grid>


                    </Grid>
                </Container>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(QuizLearn);
