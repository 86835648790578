import { Box, Button, Container, Divider, Fade, Grid, Paper, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { fade, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import './Constants/style.css'
import CachedIcon from '@material-ui/icons/Cached';
import { theme } from './Constants/CustomTheme';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import API from './Constants/API';
import Colors from './Constants/Colors';
import clsx from 'clsx';
import { withSnackbar } from 'notistack';

const DataFile = require('./Constants/WislDataset');


const useStyles = () => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    },
    button: {
        margin: 2,
        textAlign: "left",
    },
    textBlock: {
        height: "100%",
        background: Colors.themeyellow,
        justifyContent: "center",
        display: "flex",
        
    },
    
    text: {
        margin: "auto",
    },
    imgBlock: {
        height: 120,
        widht: 120,
    }
});
var imagesLoaded = 0;

class MatrixMatchHide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'e',
            optionHeader: '',
            buttonTitle: '',
            answer: false,
            currentIndex: -1,
            cstyles: [null, null, null, null],
            options: [],
            previousItem: null,
            currentItemID:-1,
            boxColor: '#fff',
            intervalTime:2000,
            fade: true,
            
        };
    }
    componentDidMount() {
        var lang = this.props.match.params.lang;
        this.setState({
            lang: lang,
        })
        this.load(lang);

    }


    load = async (lang) => {
        if (lang == "e") {

            this.setState(
                {
                    optionHeader: "Please Match Text And Image Correctly ",
                    buttonTitle: "Next",
                    list: await DataFile.english.list,
                }
            );
        }
        else if (lang == "h") {
            this.setState(
                {
                    optionHeader: "कृपया सही विकल्प चुनें",
                    buttonTitle: "आगे",
                    list: await DataFile.hindi.list,

                }
            );

        }
        else if (lang == "p") {
            this.setState(
                {
                    optionHeader: "ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਵਿਕਲਪ ਦੀ ਚੋਣ ਕਰੋ",
                    buttonTitle: "ਅੱਗੇ",
                    list: await DataFile.punjabi.list,

                }
            );
        }

        this.loadData(lang);
    }
    loadData = (params) => {
        console.log(this.state.list);

        if (this.state.list.length == 0) {

            return;
        }
        // this.setState({
        //     fade:!this.state.fade,

        // });
        var options = [];
        var index = 0;
        for (var i = 0; i < 6; i++) {
            var nextRow = this.getRow();
            if (!(options.some(ob => ob.Text === nextRow.Text))) {
                options[index++] = { ImgPath: API.Imgerveraddress + nextRow.ImgPath, Text: '', WID: i, ID: index, Enabled: true };
                options[index++] = { ImgPath: '', Text: nextRow.Text, WID: i, ID: index, Enabled: true };
            }
            else {
                i--;
            }
        }
        var randomOptions = this.shuffleArray(options);
        var arr = [false, false, false, false, false, false];

        this.setState({
            answer: false,
            options: randomOptions,
            correctOption: arr,
            previousItem: null,
            previousItemID: -1,
            boxColor: '#fff',
            intervalTime:2000,
            currentItemID: -1,

        })

    }
    shuffleArray = (arr) => {
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
    }
    getRow = () => {
        var index = Math.floor(Math.random() * this.state.list.length);
        //  console.log( index);
        var ob = this.state.list[index];
        return ob;

    }


    handleClickMe = (currentItem) => {
        this.setState({
            currentItemID:currentItem.ID,
            intervalTime:1000,
        })
        if (!currentItem.Enabled) {
            return;
        }
        var previousItem = this.state.previousItem;
        currentItem.Enabled = false;
        if (previousItem === null) {
            this.setState({
                previousItem: currentItem,
                previousItemID: currentItem.ID,
            });
            return;
        }
        var arr = this.state.cstyles;
        if ((previousItem.ID !== currentItem.ID) && (previousItem.WID === currentItem.WID)) {
            var arr = this.state.correctOption;
            arr[currentItem.WID] = true;
            this.setState({
                correctOption: arr,
                output: 'Great, Correct match found',

            });

            this.props.enqueueSnackbar('Great, Correct match found' , { 
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
    
        }
        else {
            this.setState({
                output: 'In-correct match, Please try again',

            });
            var fadeCurrentItemID = () => {
                this.setState({
                    currentItemID: -1,
                })
            };
                var interval = setInterval(
                    function () {
                        fadeCurrentItemID();
                        console.log('clear Current item interval');
                        clearInterval(interval);
                    }, 1000);

                    this.props.enqueueSnackbar('In-correct match, Please try again' , { 
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                    });
            currentItem.Enabled = true;
            this.state.previousItem.Enabled = true;

        }
        this.setState({
            previousItem: null,
            previousItemID: -1,
        });
    }



    nextQuestion = () => {
        this.setState({
            fade:true,
        });
        this.loadData();
    }
    imageLoadComplete = (params) => {

        imagesLoaded++;

            var fade = (val) => {
                this.setState({
                    fade: val,
                })
            };
            if (imagesLoaded >= 6) {
                var interval = setInterval(
                    function () {
                        fade(false);
                        console.log('clear interval');
                        clearInterval(interval);
                        imagesLoaded=0;

                    }, 3000);
            }

    }

    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid container justify="center">
                        <Grid item md={8}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>{this.state.optionHeader} </p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="outlined" onClick={() => { this.nextQuestion() }}> Next Question </Button> <a href="#gried-view" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-view">


                                    <Paper className={classes.paper} >


                                        <Box paddingTop={5} paddingBottom={5}>
                                            <Grid container justify="center" spacing={2} >

                                                <>
                                                    {this.state.options.map((row, index) => (
                                                        <Grid item md={2} sm={3} xs={4} key={index}   onClick={() => { this.handleClickMe(row) }} >
                                                            <Box style={{ borderWidth: 3, borderStyle: 'solid', borderColor:Colors.themeblue }} className={classes.textBlock} >

                                                            <Fade
                                                                in={
                                                                    (this.state.correctOption[row.WID] || this.state.currentItemID===row.ID)
                                                                        ?
                                                                        !this.state.fade
                                                                        :
                                                                        this.state.fade
                                                                }
                                                                timeout={this.state.intervalTime} >

                                                                {(row.Text === '') ?
                                                                    <Box className={classes.textBlock} justifyItems={"center"} onLoad={() => this.imageLoadComplete(index)}>

                                                                        <img src={row.ImgPath} alt={row.Text}  />
                                                                    </Box>
                                                                    :
                                                                    <Box className={classes.textBlock} justifyItems={"center"}>
                                                                        <Typography display="inline" variant="h3" className={classes.text} >{row.Text}</Typography>
                                                                    </Box>
                                                                }
                                                            </Fade>
                                                            </Box>
                                                     
                                                        </Grid>

                                                    ))}
                                                </>
                                            </Grid>

                                        </Box>
                                        {/* <Box paddingTop={5} paddingBottom={5} style={{ background: this.state.boxColor, color: '#fff' }}>
                                            <Typography variant={"h5"} align={"center"} display={"block"} color={"inherit"}>
                                                {this.state.output}
                                            </Typography>
                                        </Box> */}

                                    </Paper>




                                </div>

                            </div>


                          
                        </Grid>


                    </Grid>
                </Container>
            </MuiThemeProvider>
        )
    }
}
export default withSnackbar(withStyles(useStyles)(MatrixMatchHide));
