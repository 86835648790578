module.exports = {
  "english": {
    "list": [
        {
          "Text": "A",
          "ImgPath": "a.png"
        },
        {
          "Text": "B",
          "ImgPath": "b.png"
        },
        {
          "Text": "C",
          "ImgPath": "c.png"
        },
        {
          "Text": "D",
          "ImgPath": "d.png"
        },
        {
          "Text": "E",
          "ImgPath": "e.png"
        },
        {
          "Text": "F",
          "ImgPath": "f.png"
        },
        {
          "Text": "G",
          "ImgPath": "g.png"
        },
        {
          "Text": "H",
          "ImgPath": "h.png"
        },
        {
          "Text": "I",
          "ImgPath": "i.png"
        },
        {
          "Text": "J",
          "ImgPath": "j.png"
        },
        {
          "Text": "K",
          "ImgPath": "k.png"
        },
        {
          "Text": "L",
          "ImgPath": "l.png"
        },
        {
          "Text": "M",
          "ImgPath": "m.png"
        },
        {
          "Text": "N",
          "ImgPath": "n.png"
        },
        {
          "Text": "O",
          "ImgPath": "o.png"
        },
        {
          "Text": "P",
          "ImgPath": "p.png"
        },
        {
          "Text": "Q",
          "ImgPath": "q.png"
        },
        {
          "Text": "R",
          "ImgPath": "r.png"
        },
        {
          "Text": "S",
          "ImgPath": "s.png"
        },
        {
          "Text": "T",
          "ImgPath": "t.png"
        },
        {
          "Text": "U",
          "ImgPath": "u.png"
        },
        {
          "Text": "V",
          "ImgPath": "v.png"
        },
        {
          "Text": "W",
          "ImgPath": "w.png"
        },
        {
          "Text": "X",
          "ImgPath": "x.png"
        },
        {
          "Text": "Y",
          "ImgPath": "y.png"
        },
        {
          "Text": "Z",
          "ImgPath": "z.png"
        }
      ]
  },
  "punjabi": {
    "list": [
        {
          "Text": "ੳ",
          "ImgPath": "p01.png"
        },
        {
          "Text": "ਅ",
          "ImgPath": "p02.png"
        },
        {
          "Text": "ੲ",
          "ImgPath": "p03.png"
        },
        {
          "Text": "ਸ",
          "ImgPath": "p04.png"
        },
        {
          "Text": "ਹ",
          "ImgPath": "p06.png"
        },
        {
          "Text": "ਕ",
          "ImgPath": "p07.png"
        },
        {
          "Text": "ਖ",
          "ImgPath": "p08.png"
        },
        {
          "Text": "ਗ",
          "ImgPath": "p10.png"
        },
        {
          "Text": "ਘ",
          "ImgPath": "p12.png"
        },
        {
          "Text": "ਙ",
          "ImgPath": "p13.png"
        },
        {
          "Text": "ਚ",
          "ImgPath": "p14.png"
        },
        {
          "Text": "ਛ",
          "ImgPath": "p15.png"
        },
        {
          "Text": "ਜ",
          "ImgPath": "p16.png"
        },
        {
          "Text": "ਝ",
          "ImgPath": "p18.png"
        },
        {
          "Text": "ਞ",
          "ImgPath": "p19.png"
        },
        {
          "Text": "ਟ",
          "ImgPath": "p20.png"
        },
        {
          "Text": "ਠ",
          "ImgPath": "p21.png"
        },
        {
          "Text": "ਡ",
          "ImgPath": "p22.png"
        },
        {
          "Text": "ਢ",
          "ImgPath": "p23.png"
        },
        {
          "Text": "ਣ",
          "ImgPath": "p24.png"
        },
        {
          "Text": "ਤ",
          "ImgPath": "p25.png"
        },
        {
          "Text": "ਥ",
          "ImgPath": "p26.png"
        },
        {
          "Text": "ਦ",
          "ImgPath": "p27.png"
        },
        {
          "Text": "ਧ",
          "ImgPath": "p28.png"
        },
        {
          "Text": "ਨ",
          "ImgPath": "p29.png"
        },
        {
          "Text": "ਪ",
          "ImgPath": "p30.png"
        },
        {
          "Text": "ਫ",
          "ImgPath": "p31.png"
        },
        {
          "Text": "ਬ",
          "ImgPath": "p33.png"
        },
        {
          "Text": "ਭ",
          "ImgPath": "p34.png"
        },
        {
          "Text": "ਮ",
          "ImgPath": "p35.png"
        },
        {
          "Text": "ਯ",
          "ImgPath": "p36.png"
        },
        {
          "Text": "ਰ",
          "ImgPath": "p37.png"
        },
        {
          "Text": "ਲ",
          "ImgPath": "p38.png"
        },
        {
          "Text": "ਵ",
          "ImgPath": "p40.png"
        },
        {
          "Text": "ੜ",
          "ImgPath": "p41.png"
        },
        {
          "Text": "ਸ਼",
          "ImgPath": "p05.png"
        },
        {
          "Text": "ਖ਼",
          "ImgPath": "p09.png"
        },
        {
          "Text": "ਗ਼",
          "ImgPath": "p11.png"
        },
        {
          "Text": "ਜ਼",
          "ImgPath": "p17.png"
        },
        {
          "Text": "ਫ਼",
          "ImgPath": "p32.png"
        }
      ]
  },
  "hindi": {
    "list": [
        {
          "Text": "अ",
          "ImgPath": "h01.png"
        },
        {
          "Text": "आ",
          "ImgPath": "h04.png"
        },
        {
          "Text": "इ",
          "ImgPath": "h05.png"
        },
        {
          "Text": "ई",
          "ImgPath": "h06.png"
        },
        {
          "Text": "उ",
          "ImgPath": "h07.png"
        },
        {
          "Text": "ऊ",
          "ImgPath": "h08.png"
        },
        {
          "Text": "ऋ",
          "ImgPath": "h09.png"
        },
        {
          "Text": "ए",
          "ImgPath": "h10.png"
        },
        {
          "Text": "ऐ",
          "ImgPath": "h11.png"
        },
        {
          "Text": "ओ",
          "ImgPath": "h12.png"
        },
        {
          "Text": "औ",
          "ImgPath": "h13.png"
        },
        {
          "Text": "अं",
          "ImgPath": "h02.png"
        },
        {
          "Text": "अः",
          "ImgPath": "h03.png"
        },
        {
          "Text": "क",
          "ImgPath": "h14.png"
        },
        {
          "Text": "ख",
          "ImgPath": "h16.png"
        },
        {
          "Text": "ग",
          "ImgPath": "h17.png"
        },
        {
          "Text": "घ",
          "ImgPath": "h18.png"
        },
        {
          "Text": "ङ",
          "ImgPath": "h19.png"
        },
        {
          "Text": "च",
          "ImgPath": "h20.png"
        },
        {
          "Text": "छ",
          "ImgPath": "h21.png"
        },
        {
          "Text": "ज",
          "ImgPath": "h22.png"
        },
        {
          "Text": "झ",
          "ImgPath": "h24.png"
        },
        {
          "Text": "ञ",
          "ImgPath": "h25.png"
        },
        {
          "Text": "ट",
          "ImgPath": "h26.png"
        },
        {
          "Text": "ठ",
          "ImgPath": "h27.png"
        },
        {
          "Text": "ड",
          "ImgPath": "h28.png"
        },
        {
          "Text": "ढ",
          "ImgPath": "h29.png"
        },
        {
          "Text": "ण",
          "ImgPath": "h30.png"
        },
        {
          "Text": "त",
          "ImgPath": "h31.png"
        },
        {
          "Text": "थ",
          "ImgPath": "h33.png"
        },
        {
          "Text": "द",
          "ImgPath": "h34.png"
        },
        {
          "Text": "ध",
          "ImgPath": "h35.png"
        },
        {
          "Text": "न",
          "ImgPath": "h36.png"
        },
        {
          "Text": "प",
          "ImgPath": "h37.png"
        },
        {
          "Text": "फ",
          "ImgPath": "h38.png"
        },
        {
          "Text": "ब",
          "ImgPath": "h39.png"
        },
        {
          "Text": "भ",
          "ImgPath": "h40.png"
        },
        {
          "Text": "म",
          "ImgPath": "h41.png"
        },
        {
          "Text": "य",
          "ImgPath": "h42.png"
        },
        {
          "Text": "र",
          "ImgPath": "h43.png"
        },
        {
          "Text": "ल",
          "ImgPath": "h44.png"
        },
        {
          "Text": "व",
          "ImgPath": "h45.png"
        },
        {
          "Text": "श",
          "ImgPath": "h46.png"
        },
        {
          "Text": "ष",
          "ImgPath": "h47.png"
        },
        {
          "Text": "स",
          "ImgPath": "h48.png"
        },
        {
          "Text": "ह",
          "ImgPath": "h49.png"
        },
        {
          "Text": "क्ष",
          "ImgPath": "h15.png"
        },
        {
          "Text": "त्र",
          "ImgPath": "h32.png"
        },
        {
          "Text": "ज्ञ",
          "ImgPath": "h23.png"
        }
      ]
  }
}