//import liraries
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { Grid, Container, Paper, Box } from '@material-ui/core';

import AvatarPlayer from './Avatar';


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { teal, deepOrange, yellow, green } from '@material-ui/core/colors';

import { theme } from './Constants/CustomTheme';

// const theme = createMuiTheme(
//     {
//         palette: {
//           primary: yellow,
//           secondary: green,
//         },
//         overrides: {
//             MuiButton: {

//                 borderRadius: 0,
//                 margin: 5,
//                 '&:hover': {
//                     background: '#fdc800',
//                     color: '#002147',
//                     borderColor: '#fdc800',
//                 }
//             }
//         },
//       }
// )

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: 5,
  },
  paperBlock: {
    padding: 10,
  },
  // backdrop: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   color: '#fff',
  // },
});
// create a component
class Index extends Component {

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Grid>
          <Container className={classes.container}>
            {/* <AvatarPlayer/> */}
            <Box padding={5}>

              <a href="https://learnisl.com">Thank you for visiting, webportal is under construction or contact us for the issue</a>
            </Box>
          </Container>
        </Grid>
      </MuiThemeProvider>
    );
  }
}
export default withStyles(useStyles)(Index);
