import { Box, Button, Container, Grid, IconButton, Paper, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { makeStyles, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import './Constants/style.css'
import CachedIcon from '@material-ui/icons/Cached';
import Avatar from './Avatar';
import API from './Constants/API';
import DbConnection from './Constants/DbConnection';
import { theme } from './Constants/CustomTheme';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';


const db = new DbConnection();

const useStyles = theme => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    },
    button: {
        margin: 2,
        textAlign: "left",
    },

});
var repeatInterval = 0;
class QuizWord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            wlist: [],
            catList: ['School', 'Colors', 'Days', 'Months', 'Fruits', 'Health', 'States'],
            langcol: 'engw',
            lang: 'e',
            searchText: '',
            hiw: '',
            pbw: '',
            engw: '',


            selected: -1,
            value: null,
            answer: false,
            currentIndex: -1,
            wid: -1,
            cstyles: [null, null, null, null],

            option1: "",
            option2: "",
            option3: "",
            option4: "",
            correctOption: "",
            correctIndex: -1,

        };
    }
    componentDidMount() {
        var lang = this.props.match.params.lang;
        this.setState({
            lang: lang,
        });
        this.load(lang);

    }

    loadData = () => {

        if (this.state.list.length == 0) {

            return;
        }
        repeatInterval = 0;
        var correctRow = this.getRow();

        const style = {
            backgroundColor: '#2c3e50',

        };


        var correctIndex = Math.floor(Math.random() * 4);

        var options = [];
        if (this.state.lang === "e") {

            options[correctIndex] = correctRow.engw;
        }
        else if (this.state.lang === "h") {
            options[correctIndex] = correctRow.hiw;

        }
        else if (this.state.lang === "p") {
            options[correctIndex] = correctRow.pbw;
        }



        for (var i = 0; i < 4; i++) {
            if (i == correctIndex) {
                continue;
            }
            else {

                var nextRow = this.getRow();


                console.log(options)
                if (this.state.lang === "e") {
                    if (!(options.includes(nextRow.engw))) {
                        options[i] = nextRow.engw;
                    }
                    else {
                        i--;
                    }
                }
                else if (this.state.lang === "h") {
                    if (!(options.includes(nextRow.hiw))) {
                        options[i] = nextRow.hiw;
                    }
                    else {
                        i--;
                    }

                }
                else if (this.state.lang === "p") {

                    if (!(options.includes(nextRow.pbw))) {
                        options[i] = nextRow.pbw;
                    }
                    else {
                        i--;
                    }

                }


            }
        }

        var correctWord = "";

        if (this.state.lang === "e") {

            correctWord = correctRow.engw;
        }
        else if (this.state.lang === "h") {
            correctWord = correctRow.hiw;
        }
        else if (this.state.lang === "p") {
            correctWord = correctRow.pbw;
        }



        this.setState({

            //video:,
            wid: correctRow.wid,
            video: correctRow.VideoPath,
            option1: options[0],
            option2: options[1],
            option3: options[2],
            option4: options[3],
            correctOption: correctWord,
            correctIndex: correctIndex,
            answer: false,
            cstyles: [null, null, null, null],
            videoPaused: false,
        })

        this.loadSignAction(correctRow.wid)
    }

    getRow = () => {
        var index = Math.floor(Math.random() * this.state.list.length);
        //  console.log( index);
        var ob = this.state.list[index];
        return ob;

    }
    load = async (params) => {

        if (params === 'e') {
            this.setState({
                langcol: 'engw',
            })
        }
        else if (params === 'p') {
            this.setState({
                langcol: 'pbw',
            })
        }
        else if (params === 'h') {
            this.setState({
                langcol: 'hiw',
            })
        }

        var result = await db.GetWordList(params);

        if (result === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                list: result,
            });
        }

        //console.log(this.getRow());
        this.loadData();

    }

    handleClickMe = (index) => {
        var arr = this.state.cstyles;
        if (index === this.state.correctIndex) {
            const style = {
                backgroundColor: "green",
                color: '#fff',
            };
            arr[index] = style;

            this.setState({
                answer: true,
                cstyles: arr,

            })
            //    console.log(this.state);
        }
        else {
            const style = {
                backgroundColor: "red",
                color: '#fff',

            };
            const style2 = {
                backgroundColor: "green",
                color: '#fff',
            };
            arr[index] = style;
            arr[this.state.correctIndex] = style2;

            this.setState({
                cstyles: arr,
                answer: false
            })
        }
        this.setState({
            currentIndex: index,
            buttonDisable: false,
        })
    }
    loadSignAction = async (wid) => {
        var result = await db.GetSignByWID(wid);
        this.setState({
            correctOptionEng: 'na',
            correctOptionHi: 'na',
            correctOptionPb: 'na',
        })
        if (result === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                data: result,
                correctOptionEng: result.English,
                correctOptionHi: result.Hindi,
                correctOptionPb: result.Punjabi,

            });
            this.playSign();
        }
    }
    playSign() {
        const data = this.state.data;

        var txt = document.getElementsByClassName('txtaSiGMLText');
        //console.log(txt.innerHTML);
        if (repeatInterval >= 5) {

            alert("please reload page");
            return;
        }
       // console.log(txt.length);
        if (txt.length === 0) {
            var interval = setInterval(
                function () {
                    console.log('repeat');
                    if (repeatInterval < 5) {
                        repeatInterval++;
                        //this.playSign();
                    }
                    else {
                        alert('If the Player is not loaded kindly check your internet connection and reload the webpage');
                        clearInterval(interval);

                    }
                }, 1000);
            return;
        }

        txt[0].innerHTML = data.SiGML;

        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

        this.setState({
            statusMsg: "Playing",
        });

    }
    wordByTag = async (params) => {
        var result = await db.GetwordByTag(params);

        if (result === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                list: result,
            });
        }
    }
    nextQuestion = () => {
        this.loadData();
    }
    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid container spacing={5}>
                        <Grid item md={3}>

                            <div className="sidebar">

                                
                                <div className="sidebar-box">
                                    <div className="sidebar-box-inner">
                                        <h3 className="sidebar-title">Categories</h3>
                                        <ul className="sidebar-categories">
                                            <>  {this.state.catList.map((row, index) => (
                                                <li key={index} onClick={() => { this.wordByTag(row) }}>{row}</li>
                                            ))}
                                            </>
                                        </ul>
                                    </div>
                                </div>

                            </div>


                        </Grid>
                        <Grid item md={9}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>Showing Random 40 results</p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="outlined" onClick={() => { this.nextQuestion() }}> Next Question </Button> <a href="#gried-view" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-view">
                                    <Grid container spacing={3}>
                                        <Grid item md={6} >
                                            <Paper className={classes.paper}>


                                                <Box margin={2}>
                                                    <Button style={this.state.cstyles[0]} onClick={() => { this.handleClickMe(0) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                        {this.state.option1}
                                                    </Button>
                                                </Box>
                                                <Box margin={2}>
                                                    <Button className={classes.button} style={this.state.cstyles[1]} onClick={() => { this.handleClickMe(1) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                        {this.state.option2}

                                                    </Button>
                                                </Box>
                                                <Box margin={2}>
                                                    <Button className={classes.button} style={this.state.cstyles[2]} onClick={() => { this.handleClickMe(2) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                        {this.state.option3}

                                                    </Button>
                                                </Box>
                                                <Box margin={2}>
                                                    <Button className={classes.button} style={  this.state.cstyles[3]} onClick={() => { this.handleClickMe(3) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                        {this.state.option4}

                                                    </Button>
                                                </Box>

                                            </Paper>

                                        </Grid>
                                        <Grid item md={6} >
                                            <Paper className={classes.paper}>
                                                <Avatar />
                                                <Box padding={5} textAlign="center">
                                                    <Button variant="contained" color="secondary">
                                                        {this.state.engw}
                                                    </Button>
                                                    <Button variant="contained" color="secondary">
                                                        {this.state.pbw}
                                                    </Button>
                                                    <Button variant="contained" color="secondary">
                                                        {this.state.hiw}
                                                    </Button>
                                                </Box>
                                            </Paper>

                                        </Grid>
                                    </Grid>



                                </div>

                            </div>


                            {/* <div>
                    <h1>welcome</h1>
                    <h2>{this.props.match.params.lang}</h2>
                    <h2>{this.props.match.params.word}</h2>

                    <button className="sidebar-search-btn disabled" type="submit" value="Login">QuizWord Course</button>
                </div> */}
                        </Grid>


                    </Grid>
                </Container>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(QuizWord);
