import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import './Constants/style.css'
import CachedIcon from '@material-ui/icons/Cached';
import { theme } from './Constants/CustomTheme';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import API from './Constants/API';

const DataFile = require('./Constants/WislDataset');


const useStyles = () => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    },
    button: {
        margin: 2,
        textAlign: "left",
    },
    textBlock: {
        borderSize: 2,
        borderStyle: 'solid',
        display: "inline-block",
        padding: 5,
        width: 120,
    },
    imgBlock: {
        height: 120,
        widht: 120,
    }
});
var repeatInterval = 0;
class SingleLetterSign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionHeader:'',
            lang:'e',
            buttonTitle:'',
            selected: -1,
            value: null,
            answer: false,
            currentIndex: -1,
            wid: -1,
            cstyles: [null, null, null, null],

            option1: "",
            option2: "",
            option3: "",
            option4: "",
            correctOption: "",
            correctIndex: -1,

        };
    }
    componentDidMount() {
        var lang = this.props.match.params.lang;
        this.setState({
            lang: lang,
        })
        this.load(lang);

    }


    load = async (lang) => {
        if (lang == "e") {

            this.setState(
                {
                    optionHeader: "Please Select Correct Option",
                    buttonTitle: "Next",
                    list: await DataFile.english.list,
                }
            );
        }
        else if (lang == "h") {
            this.setState(
                {
                    optionHeader: "कृपया सही विकल्प चुनें",
                    buttonTitle: "आगे",
                    list: await DataFile.hindi.list,

                }
            );

        }
        else if (lang == "p") {
            this.setState(
                {
                    optionHeader: "ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਵਿਕਲਪ ਦੀ ਚੋਣ ਕਰੋ",
                    buttonTitle: "ਅੱਗੇ",
                    list: await DataFile.punjabi.list,

                }
            );
        }
        this.loadData(lang);
    }
    loadData = (params) => {




        console.log(this.state.list);
        if (this.state.list.length == 0) {

            return;
        }

        var correctRow = this.getRow();



        var correctIndex = Math.floor(Math.random() * 4);

        var options = [];
        options[correctIndex] = {Img:API.Imgerveraddress + correctRow.ImgPath, Text:correctRow.Text};;



        for (var i = 0; i < 4; i++) {
            if (i == correctIndex) {
                continue;
            }

            var nextRow = this.getRow();


            if (!(options.some(ob => ob.Text === nextRow.Text))) {
                options[i] = {Img:API.Imgerveraddress + nextRow.ImgPath, Text:nextRow.Text};
            }
            else {
                i--;
            }


        }

        var correctWord = "";
        correctWord = correctRow.Text;

        this.setState({

            //video:,
            option1: options[0],
            option2: options[1],
            option3: options[2],
            option4: options[3],
            correctOption: correctWord,
            correctIndex: correctIndex,
            answer: false,
            cstyles: [null, null, null, null],
            videoPaused: false,
        })

    }

    getRow = () => {
        var index = Math.floor(Math.random() * this.state.list.length);
        //  console.log( index);
        var ob = this.state.list[index];
        return ob;

    }


    handleClickMe = (index) => {
        var arr = this.state.cstyles;
        if (index === this.state.correctIndex) {
            const style = {
                backgroundColor: "green",
                color: '#fff',
            };
            arr[index] = style;

            this.setState({
                answer: true,
                cstyles: arr,

            })
            //    console.log(this.state);
        }
        else {
            const style = {
                backgroundColor: "red",
                color: '#fff',

            };
            const style2 = {
                backgroundColor: "green",
                color: '#fff',
            };
            arr[index] = style;
            arr[this.state.correctIndex] = style2;

            this.setState({
                cstyles: arr,
                answer: false
            })
        }
        this.setState({
            currentIndex: index,
            buttonDisable: false,
        })
    }



    nextQuestion = () => {
        this.loadData();
    }
    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid container justify="center">
                        <Grid item md={6}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>{this.state.optionHeader} </p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="outlined" onClick={() => { this.nextQuestion() }}> Next Question </Button> <a href="#gried-view" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-view">


                                    <Paper className={classes.paper}>
                                        <Box margin={5} textAlign={"center"}>
                                            <Typography variant="h1" color="primary" className={classes.textBlock}>{this.state.correctOption}</Typography>
                                        </Box>
                                        <Grid container justify="center">

                                            <Grid item md={6}>
                                                <Box textAlign="center">

                                                <Button style={this.state.cstyles[0]} onClick={() => { this.handleClickMe(0) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                    <img src={this.state.option1.Img} alt={this.state.option1.Text} className={classes.imgBlock} />
                                                </Button>
                                                </Box>

                                            </Grid>
                                            <Grid item md={6}>
                                            <Box textAlign="center">

                                                <Button className={classes.button} style={this.state.cstyles[1]} onClick={() => { this.handleClickMe(1) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                    <img src={this.state.option2.Img} alt={this.state.option2.Text} className={classes.imgBlock} />
                                                    

                                                </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item md={6}>

                                            <Box textAlign="center">
                                                <Button className={classes.button} style={this.state.cstyles[2]} onClick={() => { this.handleClickMe(2) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                    <img src={this.state.option3.Img} alt={this.state.option3.Text} className={classes.imgBlock} />
                                                    


                                                </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item md={6}>
                                            <Box textAlign="center">

                                                <Button className={classes.button} style={this.state.cstyles[3]} onClick={() => { this.handleClickMe(3) }} startIcon={<RadioButtonUncheckedIcon />}>
                                                    <img src={this.state.option4.Img} alt={this.state.option4.Text} className={classes.imgBlock} />
                                                    


                                                </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Paper>




                                </div>

                            </div>


                            {/* <div>
                    <h1>welcome</h1>
                    <h2>{this.props.match.params.lang}</h2>
                    <h2>{this.props.match.params.word}</h2>

                    <button className="sidebar-search-btn disabled" type="submit" value="Login">QuizWord Course</button>
                </div> */}
                        </Grid>


                    </Grid>
                </Container>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(SingleLetterSign);
