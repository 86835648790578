import { Box, Button, Container, Divider, Fade, Grid, Paper, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import './Constants/style.css'
import CachedIcon from '@material-ui/icons/Cached';
import { theme } from './Constants/CustomTheme';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import API from './Constants/API';
import clsx from 'clsx';
import { SnackbarProvider, useSnackbar, withSnackbar } from 'notistack';
import Colors from './Constants/Colors';

const DataFile = require('./Constants/WislDataset');


const useStyles = () => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    },
    button: {
        margin: 2,
        textAlign: "left",
    },
    textBlock: {
        height: "100%",
        background: '#fdc800',
        justifyContent: "center",
        display: "flex",
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: Colors.themegrey,

        '&:hover': {
            borderColor: Colors.themeblue,
            background: Colors.themeyellow,
        }
    },
    selected: {
        borderColor: '#002147',
    },
    correct: {
        borderColor: '#008c3f',
    },
    text: {
        margin: "auto",
    },
    imgBlock: {
        height: 120,
        widht: 120,
    }
});
var repeatInterval = 0;
class MatrixMatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'e',
            optionHeader: '',
            buttonTitle: '',
            answer: false,
            currentIndex: -1,
            cstyles: [null, null, null, null],
            options: [],
            correctOption: "",
            correctIndex: -1,
            previousItem: null,
            correct: false,
            boxColor: '#fff',
            fade: true,
        };
    }
    componentDidMount() {
        var lang = this.props.match.params.lang;
        this.setState({
            lang: lang,
        })
        this.load(lang);

    }


    load = async (lang) => {
        if (lang == "e") {

            this.setState(
                {
                    optionHeader: "Please Match Text And Image Correctly ",
                    buttonTitle: "Next",
                    list: await DataFile.english.list,
                }
            );
        }
        else if (lang == "h") {
            this.setState(
                {
                    optionHeader: "कृपया सही विकल्प चुनें",
                    buttonTitle: "आगे",
                    list: await DataFile.hindi.list,

                }
            );

        }
        else if (lang == "p") {
            this.setState(
                {
                    optionHeader: "ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਵਿਕਲਪ ਦੀ ਚੋਣ ਕਰੋ",
                    buttonTitle: "ਅੱਗੇ",
                    list: await DataFile.punjabi.list,

                }
            );
        }

        this.loadData(lang);
    }
    loadData = (params) => {
        console.log(this.state.list);
        if (this.state.list.length == 0) {

            return;
        }
        // this.setState({
        //     fade:!this.state.fade,

        // });
        var options = [];
        var index = 0;
        for (var i = 0; i < 6; i++) {
            var nextRow = this.getRow();
            if (!(options.some(ob => ob.Text === nextRow.Text))) {
                options[index++] = { ImgPath: API.Imgerveraddress + nextRow.ImgPath, Text: '', WID: i, ID: index, Enabled: true };
                options[index++] = { ImgPath: '', Text: nextRow.Text, WID: i, ID: index, Enabled: true };
            }
            else {
                i--;
            }
        }
        var randomOptions = this.shuffleArray(options);
        var arr = [false, false, false, false, false, false];

        this.setState({
            answer: false,
            options: randomOptions,
            correctOption: arr,
            previousItem: null,
            previousItemID: -1,
            boxColor: '#fff',
            correct: false,

        })

    }
    shuffleArray = (arr) => {
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
    }
    getRow = () => {
        var index = Math.floor(Math.random() * this.state.list.length);
        //  console.log( index);
        var ob = this.state.list[index];
        return ob;

    }


    handleClickMe = (currentItem) => {
        if (!currentItem.Enabled) {
            return;
        }
        var previousItem = this.state.previousItem;
        currentItem.Enabled = false;
        if (previousItem === null) {
            this.setState({
                previousItem: currentItem,
                previousItemID: currentItem.ID,
            });
            return;
        }
        var arr = this.state.cstyles;
        if ((previousItem.ID !== currentItem.ID) && (previousItem.WID === currentItem.WID)) {
            var arr = this.state.correctOption;
            arr[currentItem.WID] = true;
            this.setState({
                correctOption: arr,
                output: 'Great, Correct match found',
                boxColor: '#008c3f',
                // correct: true,

            });

        this.props.enqueueSnackbar('Great, Correct match found' , { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });

        }
        else {
            this.setState({
                output: 'In-correct match, Please try again',
                boxColor: 'red',
           //     correct: false,

            });
            this.props.enqueueSnackbar('In-correct match, Please try again' ,{ 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            currentItem.Enabled = true;
            this.state.previousItem.Enabled = true;

        }
        this.setState({
            previousItem: null,
            previousItemID: -1,
        });
    }

   
    


    nextQuestion = () => {
        this.loadData();
    }
    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid container justify="center">
                        <Grid item md={8}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>{this.state.optionHeader} </p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="outlined" onClick={() => { this.nextQuestion() }}> Next Question </Button> <a href="#gried-view" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-view">


                                    <Paper className={classes.paper} >


                                        <Box paddingTop={5} paddingBottom={5}>
                                            <Grid container justify="center" spacing={2} >

                                                <>
                                                    {this.state.options.map((row, index) => (
                                                        <Grid item md={2} sm={3} xs={4} key={index}>
                                                            <Fade in={(this.state.correctOption[row.WID]) ? !this.state.fade : this.state.fade} timeout={index * 500} >

                                                                {(row.Text === '') ?
                                                                    <Box className={clsx(classes.textBlock, (row.ID === this.state.previousItemID) ? classes.selected : null, (this.state.correctOption[row.WID]) ? classes.correct : null)} justifyItems={"center"}>

                                                                        <img src={row.ImgPath} alt={row.Text} onClick={() => { this.handleClickMe(row) }} />
                                                                    </Box>
                                                                    :
                                                                    <Box className={clsx(classes.textBlock, (row.ID === this.state.previousItemID) ? classes.selected : null, (this.state.correctOption[row.WID]) ? classes.correct : null)} onClick={() => { this.handleClickMe(row) }} justifyItems={"center"}>
                                                                        <Typography display="inline" variant="h3" className={classes.text} >{row.Text}</Typography>
                                                                    </Box>
                                                                }
                                                            </Fade>
                                                        </Grid>

                                                    ))}
                                                </>
                                            </Grid>

                                        </Box>
                                        {/* <Fade in={this.state.correct} timeout={1000}>

                                            <Box paddingTop={5} paddingBottom={5} style={{ background: this.state.boxColor, color: '#fff' }}>
                                                <Typography variant={"h5"} align={"center"} display={"block"} color={"inherit"}>
                                                    {this.state.output}
                                                </Typography>
                                            </Box>
                                        </Fade> */}

                                    </Paper>




                                </div>

                            </div>


                            {/* <div>
                    <h1>welcome</h1>
                    <h2>{this.props.match.params.lang}</h2>
                    <h2>{this.props.match.params.word}</h2>

                    <button className="sidebar-search-btn disabled" type="submit" value="Login">QuizWord Course</button>
                </div> */}
                        </Grid>


                    </Grid>
                </Container>

            </MuiThemeProvider>
        )
    }
}
export default withSnackbar(withStyles(useStyles)(MatrixMatch));
