import { theme } from './Constants/CustomTheme';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';
import { Grid, Paper, Container, Button, Box, TextField, Fade } from '@material-ui/core';
import React, { Component } from 'react'
import Colors from './Constants/Colors';
import API from './Constants/API';
const DataFile = require('./Constants/WislDataset');

const useStyles = () => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },

    textBlock: {
        height: "100%",
        background: '#fdc800',
        justifyContent: "center",
        display: "flex",
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: Colors.themegrey,

        '&:hover': {
            borderColor: Colors.themeblue,
            background: Colors.themeyellow,
        }
    },

    imgBlock: {
        height: 120,
        widht: 120,
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: Colors.tomatoRed,
        '&:hover': {
            borderColor: Colors.themeblue,

        }
    }
});
class ISLKeyboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            output: "",
            outputImg: [],
            type: true,
        }
    }
    componentDidMount() {
        var lang = this.props.match.params.lang;
        this.setState({
            lang: lang,
            type: (this.props.match.params?.type) ? true : false,
        })
        this.load(lang);
    }
    load = async (lang) => {
        if (lang == "e") {

            this.setState(
                {
                    optionHeader: "Please Set The Correct Squence of Sign Images to Make a Word",
                    buttonTitle: "Next",
                    list: await DataFile.english.list,
                }
            );
        }
        else if (lang == "h") {
            this.setState(
                {
                    optionHeader: "कृपया सही विकल्प चुनें",
                    buttonTitle: "आगे",
                    list: await DataFile.hindi.list,

                }
            );

        }
        else if (lang == "p") {
            this.setState(
                {
                    optionHeader: "ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਵਿਕਲਪ ਦੀ ਚੋਣ ਕਰੋ",
                    buttonTitle: "ਅੱਗੇ",
                    list: await DataFile.punjabi.list,

                }
            );
        }
    }
    handleClickImage = (params) => {
        var output = this.state.output + params.Text;
        this.setState({
            output: output,
        });
    }
    handleClickText = (params) => {
        var output = this.state.outputImg;
        output.push(params);
        this.setState({
            outputImg: output,
        });
    }



    render() {


        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid container justify="center">
                        <Grid item md={8}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>{this.state.optionHeader} </p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="outlined" onClick={() => { this.nextQuestion() }}> Next Question </Button> <a href="#gried-view" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>


                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-view">
                                    <Paper className={classes.paper} >
                                        <Box paddingTop={5} paddingBottom={5}>
                                            <Grid container justify={"center"}>
                                                {(this.state.type) ?
                                                    <Grid item md={6}>
                                                        <TextField
                                                            value={this.state.output}
                                                            readOnly={true}
                                                        />
                                                    </Grid>
                                                    :
                                                    this.state.outputImg.map((row, index) => (

                                                        <Grid item md={1} sm={2} xs={2} >
                                                            <Box className={classes.textBlock} justifyItems={"center"}>
                                                                <img src={API.Imgerveraddress + row.ImgPath} alt={row.Text} onClick={() => { this.handleClickImage(row) }} />
                                                            </Box>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Grid container justify="center" spacing={2} >
                                                <>
                                                    {this.state.list.map((row, index) => (
                                                        <Grid item md={1} sm={2} xs={2} key={index}>
                                                            <Fade in={true} timeout={2000} >

                                                                {(this.state.type) ?
                                                                    <Box className={classes.textBlock} justifyItems={"center"}>

                                                                        <img src={API.Imgerveraddress + row.ImgPath} alt={row.Text} onClick={() => { this.handleClickImage(row) }} />
                                                                    </Box>
                                                                    :
                                                                    <Button color="primary" variant={"contained"} onClick={() => { this.handleClickText(row) }}>
                                                                        {row.Text}
                                                                    </Button>
                                                                }
                                                            </Fade>
                                                        </Grid>

                                                    ))}
                                                </>


                                                {(this.state.type) ?
                                                    <Grid item md={1} sm={2} xs={2} >
                                                        <Fade in={true} timeout={2000} >

                                                            <Box className={classes.textBlock} justifyItems={"center"}>

                                                                <img src={API.Imgerveraddress + "blank.png"} alt={"space"} onClick={() => { this.handleClickImage({ Text: " ", ImgPath: "missing.png" }) }} />
                                                            </Box>
                                                        </Fade>
                                                    </Grid>
                                                    :
                                                    <Grid item md={2} sm={3} xs={3} >
                                                        <Fade in={true} timeout={2000} >

                                                            <Button color="primary" variant={"contained"} onClick={() => { this.handleClickText({ Text: " ", ImgPath: "blank.png" }) }}>
                                                                Space
                                                                    </Button>
                                                        </Fade>
                                                    </Grid>
                                                }
                                            </Grid>

                                        </Box>

                                    </Paper>




                                </div>

                            </div>



                        </Grid>


                    </Grid>
                </Container>

            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(ISLKeyboard);
