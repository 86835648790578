module.exports = {
    "data":

    [
        {
            "WID": "1",
            "EWord": "Act",
            "HWord": "कार्य",
            "PWord": "ਕਾਰਵਾਈ",
            "VideoPath": "Act.mp4"
        },
        {
            "WID": "2",
            "EWord": "Address",
            "HWord": "पता",
            "PWord": "ਪਤਾ",
            "VideoPath": "Address.mp4"
        },
        {
            "WID": "3",
            "EWord": "Aeroplane",
            "HWord": "विमान",
            "PWord": "ਹਵਾਈ ਜਹਾਜ਼",
            "VideoPath": "Aeroplane.mp4"
        },
        {
            "WID": "4",
            "EWord": "After",
            "HWord": "उपरांत",
            "PWord": "ਬਾਅਦ",
            "VideoPath": "After.mp4"
        },
        {
            "WID": "5",
            "EWord": "Anger",
            "HWord": "गुस्सा",
            "PWord": "ਗੁੱਸਾ",
            "VideoPath": "Anger.mp4"
        },
        {
            "WID": "6",
            "EWord": "Arms",
            "HWord": "भुजाएँ",
            "PWord": "ਬਾਹਾਂ",
            "VideoPath": "Arms.mp4"
        },
        {
            "WID": "7",
            "EWord": "Auto",
            "HWord": "ऑटो",
            "PWord": "ਆਟੋ",
            "VideoPath": "Auto.mp4"
        },
        {
            "WID": "8",
            "EWord": "Baby",
            "HWord": "बच्चा",
            "PWord": "ਬੱਚਾ",
            "VideoPath": "Baby.mp4"
        },
        {
            "WID": "9",
            "EWord": "Back",
            "HWord": "कमर",
            "PWord": "ਪਿੱਠ",
            "VideoPath": "Back.mp4"
        },
        {
            "WID": "10",
            "EWord": "Back",
            "HWord": "पीछे",
            "PWord": "ਪਿੱਛਾ",
            "VideoPath": "Back.mp4"
        },
        {
            "WID": "11",
            "EWord": "Black",
            "HWord": "काला",
            "PWord": "ਕਾਲਾ",
            "VideoPath": "Black.mp4"
        },
        {
            "WID": "12",
            "EWord": "Bear",
            "HWord": "भालू",
            "PWord": "ਰਿੱਛ",
            "VideoPath": "Bear.mp4"
        },
        {
            "WID": "13",
            "EWord": "Before",
            "HWord": "पहले",
            "PWord": "ਪਹਿਲਾਂ",
            "VideoPath": "Before.mp4"
        },
        {
            "WID": "14",
            "EWord": "Between",
            "HWord": "बीच",
            "PWord": "ਵਿਚਕਾਰ",
            "VideoPath": "Between.mp4"
        },
        {
            "WID": "15",
            "EWord": "Birth",
            "HWord": "जन्म",
            "PWord": "ਜਨਮ",
            "VideoPath": "Birth.mp4"
        },
        {
            "WID": "16",
            "EWord": "Bite",
            "HWord": "काटना",
            "PWord": "ਵੱਢਣਾ ",
            "VideoPath": "Bite.mp4"
        },
        {
            "WID": "17",
            "EWord": "Black Board",
            "HWord": "ब्लैक बोर्ड",
            "PWord": "ਬਲੈਕ ਬੋਰਡ",
            "VideoPath": "Black_Board.mp4"
        },
        {
            "WID": "18",
            "EWord": "Blood",
            "HWord": "रक्त",
            "PWord": "ਲਹੂ",
            "VideoPath": "Blood.mp4"
        },
        {
            "WID": "19",
            "EWord": "Blow",
            "HWord": "फूँकना ",
            "PWord": "ਫੂੰਕਣਾ ",
            "VideoPath": "Blow.mp4"
        },
        {
            "WID": "20",
            "EWord": "Blue",
            "HWord": "नीला",
            "PWord": "ਨੀਲਾ",
            "VideoPath": "Blue.mp4"
        },
        {
            "WID": "21",
            "EWord": "Body",
            "HWord": "शरीर",
            "PWord": "ਸਰੀਰ",
            "VideoPath": "Body.mp4"
        },
        {
            "WID": "22",
            "EWord": "Body Parts",
            "HWord": "शरीर का अंग",
            "PWord": "ਸਰੀਰ ਦੇ ਅੰਗ",
            "VideoPath": "Body_Parts.mp4"
        },
        {
            "WID": "23",
            "EWord": "Ball",
            "HWord": "गेंद",
            "PWord": "ਗੇਂਦ",
            "VideoPath": "Ball.mp4"
        },
        {
            "WID": "24",
            "EWord": "Book",
            "HWord": "किताब",
            "PWord": "ਕਿਤਾਬ",
            "VideoPath": "Book.mp4"
        },
        {
            "WID": "25",
            "EWord": "Boy",
            "HWord": "लड़का",
            "PWord": "ਮੁੰਡਾ",
            "VideoPath": "Boy.mp4"
        },
        {
            "WID": "26",
            "EWord": "Brain",
            "HWord": "दिमाग",
            "PWord": "ਦਿਮਾਗ",
            "VideoPath": "Brain.mp4"
        },
        {
            "WID": "27",
            "EWord": "Breathe",
            "HWord": "सांस",
            "PWord": "ਸਾਹ",
            "VideoPath": "Breathe.mp4"
        },
        {
            "WID": "28",
            "EWord": "Brother",
            "HWord": "भाई",
            "PWord": "ਭਰਾ",
            "VideoPath": "Brother.mp4"
        },
        {
            "WID": "29",
            "EWord": "Brother_in_law",
            "HWord": "साला",
            "PWord": "ਜੀਜਾ",
            "VideoPath": "Brother_in_law.mp4"
        },
        {
            "WID": "30",
            "EWord": "Brother-in-Law",
            "HWord": "देवर",
            "PWord": "ਦਿਓਰ ",
            "VideoPath": "Brother-in-Law.mp4"
        },
        {
            "WID": "31",
            "EWord": "Brown",
            "HWord": "भूरा",
            "PWord": "ਭੂਰਾ",
            "VideoPath": "Brown.mp4"
        },
        {
            "WID": "32",
            "EWord": "Calculator",
            "HWord": "कल्कुलैटर",
            "PWord": "ਕੈਲਕੁਲੇਟਰ",
            "VideoPath": "Calculator.mp4"
        },
        {
            "WID": "33",
            "EWord": "Camel",
            "HWord": "ऊंट",
            "PWord": "ਊਠ",
            "VideoPath": "Camel.mp4"
        },
        {
            "WID": "34",
            "EWord": "Car",
            "HWord": "कार",
            "PWord": "ਕਾਰ",
            "VideoPath": "Car.mp4"
        },
        {
            "WID": "35",
            "EWord": "Cat",
            "HWord": "बिल्ली",
            "PWord": "ਬਿੱਲੀ",
            "VideoPath": "Cat.mp4"
        },
        {
            "WID": "36",
            "EWord": "Catch",
            "HWord": "पकड़",
            "PWord": "ਫੜ",
            "VideoPath": "Catch.mp4"
        },
        {
            "WID": "37",
            "EWord": "Caught",
            "HWord": "पकड़ लेना ",
            "PWord": "ਪਕੜ ਲੈਣਾ ",
            "VideoPath": "Caught.mp4"
        },
        {
            "WID": "38",
            "EWord": "Celebrate",
            "HWord": "मनाना",
            "PWord": "ਮਨਾਉਣਾ",
            "VideoPath": "Celebrate.mp4"
        },
        {
            "WID": "39",
            "EWord": "Chair",
            "HWord": "कुर्सी",
            "PWord": "ਕੁਰਸੀ",
            "VideoPath": "Chair.mp4"
        },
        {
            "WID": "40",
            "EWord": "Chart",
            "HWord": "चार्ट",
            "PWord": "ਚਾਰਟ",
            "VideoPath": "Chart.mp4"
        },
        {
            "WID": "41",
            "EWord": "Chew",
            "HWord": "चबाना",
            "PWord": "ਚਬਾਉਣਾ",
            "VideoPath": "Chew.mp4"
        },
        {
            "WID": "42",
            "EWord": "Children",
            "HWord": "बच्चे",
            "PWord": "ਨਿਆਣੇ",
            "VideoPath": "Children.mp4"
        },
        {
            "WID": "43",
            "EWord": "Chowk",
            "HWord": "चौक",
            "PWord": "ਚੌਕ",
            "VideoPath": "Chowk.mp4"
        },
        {
            "WID": "44",
            "EWord": "Classroom",
            "HWord": "कक्षा",
            "PWord": "ਕਲਾਸਰੂਮ",
            "VideoPath": "Classroom.mp4"
        },
        {
            "WID": "45",
            "EWord": "Clean",
            "HWord": "साफ",
            "PWord": "ਸਾਫ",
            "VideoPath": "Clean.mp4"
        },
        {
            "WID": "46",
            "EWord": "Clip",
            "HWord": "क्लिप",
            "PWord": "ਕਲਿਪ",
            "VideoPath": "Clip.mp4"
        },
        {
            "WID": "47",
            "EWord": "Clock",
            "HWord": "घड़ी",
            "PWord": "ਘੜੀ",
            "VideoPath": "Clock.mp4"
        },
        {
            "WID": "48",
            "EWord": "Close",
            "HWord": "बंद",
            "PWord": "ਬੰਦ",
            "VideoPath": "Close.mp4"
        },
        {
            "WID": "49",
            "EWord": "Cloud",
            "HWord": "बादल",
            "PWord": "ਬੱਦਲ",
            "VideoPath": "Cloud.mp4"
        },
        {
            "WID": "50",
            "EWord": "Cold",
            "HWord": "ठंड",
            "PWord": "ਸਰਦੀ",
            "VideoPath": "Cold.mp4"
        },
        {
            "WID": "51",
            "EWord": "Comb",
            "HWord": "कंघी",
            "PWord": "ਕੰਘਾ",
            "VideoPath": "Comb.mp4"
        },
        {
            "WID": "52",
            "EWord": "Compass",
            "HWord": "कंपास",
            "PWord": "ਕੰਪਾਸ",
            "VideoPath": "Compass.mp4"
        },
        {
            "WID": "53",
            "EWord": "Concentrate",
            "HWord": "ध्यान ",
            "PWord": "ਧਿਆਨ\r\n",
            "VideoPath": "Concentrate.mp4"
        },
        {
            "WID": "54",
            "EWord": "Confident",
            "HWord": "विश्वास",
            "PWord": "ਭਰੋਸਾ",
            "VideoPath": "Confident.mp4"
        },
        {
            "WID": "55",
            "EWord": "Confusion",
            "HWord": "उलझन",
            "PWord": "ਉਲਝਣ",
            "VideoPath": "Confusion.mp4"
        },
        {
            "WID": "56",
            "EWord": "Cook",
            "HWord": "भोजन बनाना",
            "PWord": "ਭੋਜਨ ਬਣਾਉਣਾ ",
            "VideoPath": "Cook.mp4"
        },
        {
            "WID": "57",
            "EWord": "Creon",
            "HWord": "क्रेयॉन",
            "PWord": "ਕ੍ਰੇਯੋਨ",
            "VideoPath": "Creon.mp4"
        },
        {
            "WID": "58",
            "EWord": "Critical",
            "HWord": "नाजुक",
            "PWord": "ਨਾਜ਼ੁਕ",
            "VideoPath": "Critical.mp4"
        },
        {
            "WID": "59",
            "EWord": "Cut",
            "HWord": "कटौती",
            "PWord": "ਕੱਟ",
            "VideoPath": "Cut.mp4"
        },
        {
            "WID": "60",
            "EWord": "Dance",
            "HWord": "नृत्य",
            "PWord": "ਨਾਚ",
            "VideoPath": "Dance.mp4"
        },
        {
            "WID": "61",
            "EWord": "Daughter",
            "HWord": "बेटी",
            "PWord": "ਧੀ",
            "VideoPath": "Daughter.mp4"
        },
        {
            "WID": "62",
            "EWord": "Deer",
            "HWord": "हिरन",
            "PWord": "ਹਿਰਨ",
            "VideoPath": "Deer.mp4"
        },
        {
            "WID": "63",
            "EWord": "Depressed",
            "HWord": "उदास",
            "PWord": "ਉਦਾਸ",
            "VideoPath": "Depressed.mp4"
        },
        {
            "WID": "64",
            "EWord": "Desk",
            "HWord": "मेज़",
            "PWord": "ਮੇਜ਼",
            "VideoPath": "Desk.mp4"
        },
        {
            "WID": "65",
            "EWord": "Dirty",
            "HWord": "गंदा",
            "PWord": "ਗੰਦਾ",
            "VideoPath": "Dirty.mp4"
        },
        {
            "WID": "66",
            "EWord": "Divorce",
            "HWord": "तलाक",
            "PWord": "ਤਲਾਕ",
            "VideoPath": "Divorce.mp4"
        },
        {
            "WID": "67",
            "EWord": "Dog",
            "HWord": "कुत्ता",
            "PWord": "ਕੁੱਤਾ",
            "VideoPath": "Dog.mp4"
        },
        {
            "WID": "68",
            "EWord": "Donkey",
            "HWord": "गधा",
            "PWord": "ਖੋਤਾ",
            "VideoPath": "Donkey.mp4"
        },
        {
            "WID": "69",
            "EWord": "Down",
            "HWord": "नीचे",
            "PWord": "ਥੱਲੇ",
            "VideoPath": "Down.mp4"
        },
        {
            "WID": "70",
            "EWord": "Drink",
            "HWord": "पीना",
            "PWord": "ਪੀਣਾ ",
            "VideoPath": "Drink.mp4"
        },
        {
            "WID": "71",
            "EWord": "Duster",
            "HWord": "झाड़न",
            "PWord": "ਝਾੜਨ",
            "VideoPath": "Duster.mp4"
        },
        {
            "WID": "72",
            "EWord": "Ear",
            "HWord": "कान",
            "PWord": "ਕੰਨ",
            "VideoPath": "Ear.mp4"
        },
        {
            "WID": "73",
            "EWord": "Eat",
            "HWord": "खा",
            "PWord": "ਖਾ",
            "VideoPath": "Eat.mp4"
        },
        {
            "WID": "74",
            "EWord": "Elbow",
            "HWord": "कुहनी",
            "PWord": "ਕੂਹਣੀ",
            "VideoPath": "Elbow.mp4"
        },
        {
            "WID": "75",
            "EWord": "Elephant",
            "HWord": "हाथी",
            "PWord": "ਹਾਥੀ",
            "VideoPath": "Elephant.mp4"
        },
        {
            "WID": "76",
            "EWord": "Enemy",
            "HWord": "दुश्मन",
            "PWord": "ਦੁਸ਼ਮਣ",
            "VideoPath": "Enemy.mp4"
        },
        {
            "WID": "77",
            "EWord": "Engagement",
            "HWord": "सगाई",
            "PWord": "ਮੰਗਣੀ",
            "VideoPath": "Engagement.mp4"
        },
        {
            "WID": "78",
            "EWord": "Enjoy",
            "HWord": "आनंद",
            "PWord": "ਅਨੰਦ",
            "VideoPath": "Enjoy.mp4"
        },
        {
            "WID": "79",
            "EWord": "Excitement",
            "HWord": "उत्साह",
            "PWord": "ਉਤਸ਼ਾਹ",
            "VideoPath": "Excitement.mp4"
        },
        {
            "WID": "80",
            "EWord": "Eye",
            "HWord": "आँख",
            "PWord": "ਅੱਖ",
            "VideoPath": "Eye.mp4"
        },
        {
            "WID": "81",
            "EWord": "Face",
            "HWord": "चेहरा",
            "PWord": "ਚਿਹਰਾ",
            "VideoPath": "Face.mp4"
        },
        {
            "WID": "82",
            "EWord": "Family",
            "HWord": "परिवार",
            "PWord": "ਪਰਿਵਾਰ",
            "VideoPath": "Family.mp4"
        },
        {
            "WID": "83",
            "EWord": "Far",
            "HWord": "दूर",
            "PWord": "ਦੂਰ",
            "VideoPath": "Far.mp4"
        },
        {
            "WID": "84",
            "EWord": "Father",
            "HWord": "पिता",
            "PWord": "ਪਿਤਾ",
            "VideoPath": "Father.mp4"
        },
        {
            "WID": "85",
            "EWord": "Father in law",
            "HWord": "ससुर",
            "PWord": "ਸਹੁਰਾ",
            "VideoPath": "Father_in_law.mp4"
        },
        {
            "WID": "86",
            "EWord": "Fedup",
            "HWord": "ਤੰਗ ਆ ਜਾਣਾ",
            "PWord": "परेशान",
            "VideoPath": "Fedup.mp4"
        },
        {
            "WID": "87",
            "EWord": "Feet",
            "HWord": "चरण",
            "PWord": "ਪੈਰ",
            "VideoPath": "Feet.mp4"
        },
        {
            "WID": "88",
            "EWord": "Finger",
            "HWord": "उंगली",
            "PWord": "ਉਂਗਲੀ",
            "VideoPath": "Finger.mp4"
        },
        {
            "WID": "89",
            "EWord": "Finger",
            "HWord": "अंगुली\r\n",
            "PWord": "ਉਂਗਲੀ",
            "VideoPath": "Finger.mp4"
        },
        {
            "WID": "90",
            "EWord": "Focused",
            "HWord": "केंद्रित\r\n",
            "PWord": "ਧਿਆਨ",
            "VideoPath": "Focused.mp4"
        },
        {
            "WID": "91",
            "EWord": "Fox",
            "HWord": "लोमड़ी",
            "PWord": "ਲੂੰਬੜੀ",
            "VideoPath": "Fox.mp4"
        },
        {
            "WID": "92",
            "EWord": "Friend",
            "HWord": "दोस्त",
            "PWord": "ਮਿੱਤਰ ",
            "VideoPath": "Friend.mp4"
        },
        {
            "WID": "93",
            "EWord": "Front",
            "HWord": "सामने",
            "PWord": "ਸਾਹਮਣੇ",
            "VideoPath": "Front.mp4"
        },
        {
            "WID": "94",
            "EWord": "Funeral",
            "HWord": "क्रिया कर्म",
            "PWord": "ਸੰਸਕਾਰ",
            "VideoPath": "Funeral.mp4"
        },
        {
            "WID": "95",
            "EWord": "Funnel",
            "HWord": "फ़नल",
            "PWord": "ਫਨਲ",
            "VideoPath": "Funnel.mp4"
        },
        {
            "WID": "96",
            "EWord": "Glue",
            "HWord": "गोंद",
            "PWord": "ਗੂੰਦ",
            "VideoPath": "Glue.mp4"
        },
        {
            "WID": "97",
            "EWord": "Goat",
            "HWord": "बकरी",
            "PWord": "ਬੱਕਰੀ",
            "VideoPath": "Goat.mp4"
        },
        {
            "WID": "98",
            "EWord": "Grand Daughter",
            "HWord": "दोहती",
            "PWord": "ਪੋਤੀ/ਦੋਹਤੀ",
            "VideoPath": "Grand_Daughter.mp4"
        },
        {
            "WID": "99",
            "EWord": "Grand Father",
            "HWord": "दादा/नाना",
            "PWord": "ਦਾਦਾ/\r\nਨਾਨਾ",
            "VideoPath": "Grand_Father.mp4"
        },
        {
            "WID": "100",
            "EWord": "Grand Mother",
            "HWord": "दादी/नानी",
            "PWord": "ਦਾਦੀ/\r\nਨਾਨੀ",
            "VideoPath": "Grand_Mother.mp4"
        },
        {
            "WID": "101",
            "EWord": "Grand Son",
            "HWord": "पोता/नवासा",
            "PWord": "ਪੋਤਾ/\r\nਦੋਹਤਾ",
            "VideoPath": "Grand_Son.mp4"
        },
        {
            "WID": "102",
            "EWord": "Green",
            "HWord": "हरा",
            "PWord": "ਹਰਾ",
            "VideoPath": "Green.mp4"
        },
        {
            "WID": "103",
            "EWord": "Grey",
            "HWord": "धूसर",
            "PWord": "ਸਲੇਟੀ",
            "VideoPath": "Grey.mp4"
        },
        {
            "WID": "104",
            "EWord": "Group",
            "HWord": "समूह",
            "PWord": "ਟੋਲਾ",
            "VideoPath": "Group.mp4"
        },
        {
            "WID": "105",
            "EWord": "Guest",
            "HWord": "मेहमान",
            "PWord": "ਪ੍ਰਾਹੁਣੇ",
            "VideoPath": "Guest.mp4"
        },
        {
            "WID": "106",
            "EWord": "Hair",
            "HWord": "केश",
            "PWord": "ਬਾਲ",
            "VideoPath": "Hair.mp4"
        },
        {
            "WID": "107",
            "EWord": "Hand",
            "HWord": "हाथ",
            "PWord": "ਹੱਥ",
            "VideoPath": "Hand.mp4"
        },
        {
            "WID": "108",
            "EWord": "Happy",
            "HWord": "प्रसन्न",
            "PWord": "ਖੁਸ਼",
            "VideoPath": "Happy.mp4"
        },
        {
            "WID": "109",
            "EWord": "Head",
            "HWord": "सिर",
            "PWord": "ਸਿਰ",
            "VideoPath": "Head.mp4"
        },
        {
            "WID": "110",
            "EWord": "Healthy",
            "HWord": "स्वस्थ",
            "PWord": "ਸਿਹਤਮੰਦ",
            "VideoPath": "Healthy.mp4"
        },
        {
            "WID": "111",
            "EWord": "Hear",
            "HWord": "सुनना",
            "PWord": "ਸੁਣਨਾ",
            "VideoPath": "Hear.mp4"
        },
        {
            "WID": "112",
            "EWord": "Heart",
            "HWord": "दिल",
            "PWord": "ਦਿਲ",
            "VideoPath": "Heart.mp4"
        },
        {
            "WID": "113",
            "EWord": "Heavy",
            "HWord": "भारी",
            "PWord": "ਭਾਰੀ",
            "VideoPath": "Heavy.mp4"
        },
        {
            "WID": "114",
            "EWord": "Helicopter",
            "HWord": "हेलीकॉप्टर",
            "PWord": "ਹੈਲੀਕਾਪਟਰ",
            "VideoPath": "Helicopter.mp4"
        },
        {
            "WID": "115",
            "EWord": "Help",
            "HWord": "मदद",
            "PWord": "ਮਦਦ",
            "VideoPath": "Help.mp4"
        },
        {
            "WID": "116",
            "EWord": "Hope",
            "HWord": "आशा",
            "PWord": "ਉਮੀਦ",
            "VideoPath": "Hope.mp4"
        },
        {
            "WID": "117",
            "EWord": "Hot",
            "HWord": "गर्म",
            "PWord": "ਗਰਮ",
            "VideoPath": "Hot.mp4"
        },
        {
            "WID": "118",
            "EWord": "Horse",
            "HWord": "घोड़ा",
            "PWord": "ਘੋੜਾ",
            "VideoPath": "Horse.mp4"
        },
        {
            "WID": "119",
            "EWord": "Husband",
            "HWord": "पति",
            "PWord": "ਘਰਵਾਲਾ",
            "VideoPath": "Husband.mp4"
        },
        {
            "WID": "120",
            "EWord": "In",
            "HWord": "भीतर",
            "PWord": "ਅੰਦਰ",
            "VideoPath": "In.mp4"
        },
        {
            "WID": "121",
            "EWord": "Ink",
            "HWord": "स्याही",
            "PWord": "ਸਿਆਹੀ",
            "VideoPath": "Ink.mp4"
        },
        {
            "WID": "122",
            "EWord": "Insult",
            "HWord": "अपमान",
            "PWord": "ਅਪਮਾਨ",
            "VideoPath": "Insult.mp4"
        },
        {
            "WID": "123",
            "EWord": "Interest",
            "HWord": "दिलचस्पी",
            "PWord": "ਦਿਲਚਸਪੀ",
            "VideoPath": "Interest.mp4"
        },
        {
            "WID": "124",
            "EWord": "Interview",
            "HWord": "इंटरव्यू",
            "PWord": "ਇੰਟਰਵਿਊ",
            "VideoPath": "Interview.mp4"
        },
        {
            "WID": "125",
            "EWord": "Jealousy",
            "HWord": "ईर्ष्या",
            "PWord": "ਈਰਖਾ",
            "VideoPath": "Jealousy.mp4"
        },
        {
            "WID": "126",
            "EWord": "Joyful",
            "HWord": "खुश",
            "PWord": "ਖੁਸ਼",
            "VideoPath": "Joyful.mp4"
        },
        {
            "WID": "127",
            "EWord": "Jump",
            "HWord": "कूद",
            "PWord": "ਛਾਲ",
            "VideoPath": "Jump.mp4"
        },
        {
            "WID": "128",
            "EWord": "Knee",
            "HWord": "घुटना",
            "PWord": "ਗੋਡਾ",
            "VideoPath": "Knee.mp4"
        },
        {
            "WID": "129",
            "EWord": "Knees",
            "HWord": "घुटने",
            "PWord": "ਘੁਟਨੇ",
            "VideoPath": "Knees.mp4"
        },
        {
            "WID": "130",
            "EWord": "Knock",
            "HWord": "दस्तक",
            "PWord": "ਦਸਤਕ",
            "VideoPath": "Knock.mp4"
        },
        {
            "WID": "131",
            "EWord": "Lady",
            "HWord": "महिला",
            "PWord": "ਔਰਤ",
            "VideoPath": "Lady.mp4"
        },
        {
            "WID": "132",
            "EWord": "Lazy",
            "HWord": "सुस्त",
            "PWord": "ਆਲਸੀ",
            "VideoPath": "Lazy.mp4"
        },
        {
            "WID": "133",
            "EWord": "Leaser",
            "HWord": "लेज़र",
            "PWord": "ਲੇਜ਼ਰ",
            "VideoPath": "Leaser.mp4"
        },
        {
            "WID": "134",
            "EWord": "Left",
            "HWord": "बाएं",
            "PWord": "ਖੱਬੇ",
            "VideoPath": "Left.mp4"
        },
        {
            "WID": "135",
            "EWord": "Leg",
            "HWord": "टांग",
            "PWord": "ਲੱਤ",
            "VideoPath": "Leg.mp4"
        },
        {
            "WID": "136",
            "EWord": "Lesson",
            "HWord": "पाठ",
            "PWord": "ਪਾਠ",
            "VideoPath": "Lesson.mp4"
        },
        {
            "WID": "137",
            "EWord": "Lie",
            "HWord": "झूठ",
            "PWord": "ਝੂਠ",
            "VideoPath": "Lie.mp4"
        },
        {
            "WID": "138",
            "EWord": "Lion",
            "HWord": "शेर",
            "PWord": "ਸ਼ੇਰ",
            "VideoPath": "Lion.mp4"
        },
        {
            "WID": "139",
            "EWord": "Lips",
            "HWord": "होंठ",
            "PWord": "ਬੁੱਲ੍ਹ",
            "VideoPath": "Lips.mp4"
        },
        {
            "WID": "140",
            "EWord": "Listen",
            "HWord": "सुनना",
            "PWord": "ਸੁਣਨਾ",
            "VideoPath": "Listen.mp4"
        },
        {
            "WID": "141",
            "EWord": "Loud",
            "HWord": "उच्च स्वर",
            "PWord": "ਉੱਚੀ ਆਵਾਜ਼",
            "VideoPath": "Loud.mp4"
        },
        {
            "WID": "142",
            "EWord": "Loving",
            "HWord": "प्यारा",
            "PWord": "ਪਿਆਰਾ",
            "VideoPath": "Loving.mp4"
        },
        {
            "WID": "143",
            "EWord": "Lunchbox",
            "HWord": "खाने का डिब्बा",
            "PWord": "ਖਾਣਾ ਖਾਣ ਦਾ ਡਿੱਬਾ",
            "VideoPath": "Lunchbox.mp4"
        },
        {
            "WID": "144",
            "EWord": "Mad",
            "HWord": "पागल",
            "PWord": "ਪਾਗਲ",
            "VideoPath": "Mad.mp4"
        },
        {
            "WID": "145",
            "EWord": "Man",
            "HWord": "आदमी",
            "PWord": "ਆਦਮੀ",
            "VideoPath": "Man.mp4"
        },
        {
            "WID": "146",
            "EWord": "Marker",
            "HWord": "मार्कर",
            "PWord": "ਮਾਰਕਰ",
            "VideoPath": "Marker.mp4"
        },
        {
            "WID": "147",
            "EWord": "Monkey",
            "HWord": "बंदर",
            "PWord": "ਬਾਂਦਰ",
            "VideoPath": "Monkey.mp4"
        },
        {
            "WID": "148",
            "EWord": "Moon",
            "HWord": "चांद",
            "PWord": "ਚੰਨ",
            "VideoPath": "Moon.mp4"
        },
        {
            "WID": "149",
            "EWord": "Mother",
            "HWord": "मां",
            "PWord": "ਮਾਂ",
            "VideoPath": "Mother.mp4"
        },
        {
            "WID": "150",
            "EWord": "Mother in law",
            "HWord": "सास",
            "PWord": "ਸੱਸ",
            "VideoPath": "Mother_in_law.mp4"
        },
        {
            "WID": "151",
            "EWord": "Motivation",
            "HWord": "प्रेरणा",
            "PWord": "ਪ੍ਰੋਤਸਾਹਨ",
            "VideoPath": "Motivation.mp4"
        },
        {
            "WID": "152",
            "EWord": "Mouth",
            "HWord": "मुँह",
            "PWord": "ਮੂੰਹ",
            "VideoPath": "Mouth.mp4"
        },
        {
            "WID": "153",
            "EWord": "Name",
            "HWord": "नाम",
            "PWord": "ਨਾਮ",
            "VideoPath": "Name.mp4"
        },
        {
            "WID": "154",
            "EWord": "Near",
            "HWord": "पास",
            "PWord": "ਨੇੜੇ",
            "VideoPath": "Near.mp4"
        },
        {
            "WID": "155",
            "EWord": "Neck",
            "HWord": "गर्दन",
            "PWord": "ਗਰਦਨ",
            "VideoPath": "Neck.mp4"
        },
        {
            "WID": "156",
            "EWord": "Negative",
            "HWord": "नकारात्मक",
            "PWord": "ਨਕਾਰਾਤਮਕ",
            "VideoPath": "Negative.mp4"
        },
        {
            "WID": "157",
            "EWord": "Neighbour",
            "HWord": "पड़ोसी",
            "PWord": "ਗੁਆਂਢੀ",
            "VideoPath": "Neighbour.mp4"
        },
        {
            "WID": "158",
            "EWord": "Nose",
            "HWord": "नाक",
            "PWord": "ਨੱਕ",
            "VideoPath": "Nose.mp4"
        },
        {
            "WID": "159",
            "EWord": "Notebook",
            "HWord": "नोटबुक",
            "PWord": "ਕਾਪੀ",
            "VideoPath": "Notebook.mp4"
        },
        {
            "WID": "160",
            "EWord": "Obedient",
            "HWord": "आज्ञाकारी",
            "PWord": "ਆਗਿਆਕਾਰ",
            "VideoPath": "Obedient.mp4"
        },
        {
            "WID": "161",
            "EWord": "On",
            "HWord": "पर",
            "PWord": "ਤੇ",
            "VideoPath": "On.mp4"
        },
        {
            "WID": "162",
            "EWord": "Open",
            "HWord": "खोलना",
            "PWord": "ਖੋਲ੍ਹਣਾ",
            "VideoPath": "Open.mp4"
        },
        {
            "WID": "163",
            "EWord": "Orange",
            "HWord": "संतरा",
            "PWord": "ਸੰਤਰਾ",
            "VideoPath": "Orange.mp4"
        },
        {
            "WID": "164",
            "EWord": "Ox",
            "HWord": "बैल",
            "PWord": "ਬਲਦ",
            "VideoPath": "Ox.mp4"
        },
        {
            "WID": "165",
            "EWord": "Pain",
            "HWord": "दर्द",
            "PWord": "ਪੀੜ",
            "VideoPath": "Pain.mp4"
        },
        {
            "WID": "166",
            "EWord": "Paper",
            "HWord": "कागज़",
            "PWord": "ਕਾਗਜ਼",
            "VideoPath": "Paper.mp4"
        },
        {
            "WID": "167",
            "EWord": "Paper Board",
            "HWord": "पेपर बोर्ड",
            "PWord": "ਪੇਪਰ ਬੋਰਡ",
            "VideoPath": "Paper_Board.mp4"
        },
        {
            "WID": "168",
            "EWord": "Parents",
            "HWord": "माता-पिता",
            "PWord": "ਮਾਪੇ",
            "VideoPath": "Parents.mp4"
        },
        {
            "WID": "169",
            "EWord": "Parrot",
            "HWord": "तोता",
            "PWord": "ਤੋਤਾ",
            "VideoPath": "Parrot.mp4"
        },
        {
            "WID": "170",
            "EWord": "Patience",
            "HWord": "धीरज",
            "PWord": "ਸਬਰ",
            "VideoPath": "Patience.mp4"
        },
        {
            "WID": "171",
            "EWord": "Pen",
            "HWord": "कलम",
            "PWord": "ਕਲਮ",
            "VideoPath": "Pen.mp4"
        },
        {
            "WID": "172",
            "EWord": "Pencil",
            "HWord": "पेंसिल",
            "PWord": "ਪੈਨਸਿਲ",
            "VideoPath": "Pencil.mp4"
        },
        {
            "WID": "173",
            "EWord": "Pencil Box",
            "HWord": "क़लमदान",
            "PWord": "ਕ਼ਲਮਦਾਨ",
            "VideoPath": "Pencil_Box.mp4"
        },
        {
            "WID": "174",
            "EWord": "People",
            "HWord": "लोग",
            "PWord": "ਲੋਕ",
            "VideoPath": "People.mp4"
        },
        {
            "WID": "175",
            "EWord": "Person",
            "HWord": "शख्स",
            "PWord": "ਮਨੁੱਖ",
            "VideoPath": "Person.mp4"
        },
        {
            "WID": "176",
            "EWord": "Picture",
            "HWord": "चित्र",
            "PWord": "ਤਸਵੀਰ",
            "VideoPath": "Picture.mp4"
        },
        {
            "WID": "177",
            "EWord": "Pink",
            "HWord": "गुलाबी",
            "PWord": "ਗੁਲਾਬੀ",
            "VideoPath": "Pink.mp4"
        },
        {
            "WID": "178",
            "EWord": "Play",
            "HWord": "खेल",
            "PWord": "ਖੇਡ",
            "VideoPath": "Play.mp4"
        },
        {
            "WID": "179",
            "EWord": "Positive",
            "HWord": "सकारात्मक",
            "PWord": "ਸਕਾਰਾਤਮਕ",
            "VideoPath": "Positive.mp4"
        },
        {
            "WID": "180",
            "EWord": "Powerful",
            "HWord": "शक्तिशाली",
            "PWord": "ਸ਼ਕਤੀਸ਼ਾਲੀ",
            "VideoPath": "Powerful.mp4"
        },
        {
            "WID": "181",
            "EWord": "Properly",
            "HWord": "अच्छी तरह से",
            "PWord": "ਸਹੀ ਢੰਗ ਨਾਲ",
            "VideoPath": "Properly.mp4"
        },
        {
            "WID": "182",
            "EWord": "Proud",
            "HWord": "गर्व",
            "PWord": "ਮਾਣ",
            "VideoPath": "Proud.mp4"
        },
        {
            "WID": "183",
            "EWord": "Punctual",
            "HWord": "समयनिष्ठ",
            "PWord": "ਸਮੇਂ ਦੇ ਪਾਬੰਦ",
            "VideoPath": "Punctual.mp4"
        },
        {
            "WID": "184",
            "EWord": "Purple",
            "HWord": "बैंगनी",
            "PWord": "ਜਾਮਨੀ",
            "VideoPath": "Purple.mp4"
        },
        {
            "WID": "185",
            "EWord": "Rabbit",
            "HWord": "खरगोश",
            "PWord": "ਖ਼ਰਗੋਸ਼",
            "VideoPath": "Rabbit.mp4"
        },
        {
            "WID": "186",
            "EWord": "Rain",
            "HWord": "बारिश",
            "PWord": "ਮੀਂਹ",
            "VideoPath": "Rain.mp4"
        },
        {
            "WID": "187",
            "EWord": "Read",
            "HWord": "पढ़ना",
            "PWord": "ਪੜ੍ਹੋ",
            "VideoPath": "Read.mp4"
        },
        {
            "WID": "188",
            "EWord": "Red",
            "HWord": "लाल",
            "PWord": "ਲਾਲ",
            "VideoPath": "Red.mp4"
        },
        {
            "WID": "189",
            "EWord": "Relative",
            "HWord": "रिश्तेदार",
            "PWord": "ਰਿਸ਼ਤੇਦਾਰ",
            "VideoPath": "Relative.mp4"
        },
        {
            "WID": "190",
            "EWord": "Responsibility",
            "HWord": "ज़िम्मेदारी",
            "PWord": "ਜ਼ਿੰਮੇਵਾਰੀ",
            "VideoPath": "Responsibility.mp4"
        },
        {
            "WID": "191",
            "EWord": "Right",
            "HWord": "दाहिने",
            "PWord": "ਸੱਜੇ",
            "VideoPath": "Right.mp4"
        },
        {
            "WID": "192",
            "EWord": "Ruler",
            "VideoPath": "Ruler.mp4"
        },
        {
            "WID": "193",
            "EWord": "Run",
            "HWord": "दौड़ना",
            "PWord": "ਭੱਜਣਾ",
            "VideoPath": "Run.mp4"
        },
        {
            "WID": "194",
            "EWord": "Sad",
            "HWord": "दुखी",
            "PWord": "ਉਦਾਸ",
            "VideoPath": "Sad.mp4"
        },
        {
            "WID": "195",
            "EWord": "Say",
            "HWord": "बोलना",
            "PWord": "ਬੋਲਣਾ",
            "VideoPath": "Say.mp4"
        },
        {
            "WID": "196",
            "EWord": "School Bag",
            "HWord": "बस्ता",
            "PWord": "ਬਸਤਾ",
            "VideoPath": "School_Bag.mp4"
        },
        {
            "WID": "197",
            "EWord": "Scissor",
            "HWord": "कैंची",
            "PWord": "ਕੈਂਚੀ",
            "VideoPath": "Scissor.mp4"
        },
        {
            "WID": "198",
            "EWord": "Scooter",
            "HWord": "स्कूटर",
            "PWord": "ਸਕੂਟਰ",
            "VideoPath": "Scooter.mp4"
        },
        {
            "WID": "199",
            "EWord": "See",
            "HWord": "देखना",
            "PWord": "ਦੇਖਣਾ",
            "VideoPath": "See.mp4"
        },
        {
            "WID": "200",
            "EWord": "Share",
            "HWord": "साझा करना",
            "PWord": "ਸਾਂਝਾ ਕਰਨਾ",
            "VideoPath": "Share.mp4"
        },
        {
            "WID": "201",
            "EWord": "Sharpener",
            "HWord": "तेज करने वाला",
            "PWord": "ਤਿੱਖਾ ਕਰਨ ਵਾਲਾ",
            "VideoPath": "Sharpener.mp4"
        },
        {
            "WID": "202",
            "EWord": "Sheep",
            "HWord": "भेड़",
            "PWord": "ਭੇਡ",
            "VideoPath": "Sheep.mp4"
        },
        {
            "WID": "203",
            "EWord": "Shelves",
            "HWord": "अलमारियाँ",
            "PWord": "ਅਲਮਾਰੀਆਂ",
            "VideoPath": "Shelves.mp4"
        },
        {
            "WID": "204",
            "EWord": "Shine",
            "HWord": "चमक",
            "PWord": "ਚਮਕ",
            "VideoPath": "Shine.mp4"
        },
        {
            "WID": "205",
            "EWord": "Ship",
            "HWord": "समुंद्री जहाज",
            "PWord": "ਸਮੁੰਦਰੀ ਜਹਾਜ਼",
            "VideoPath": "Ship.mp4"
        },
        {
            "WID": "206",
            "EWord": "Shoulder",
            "HWord": "कन्धा",
            "PWord": "ਮੋਢਾ",
            "VideoPath": "Shoulder.mp4"
        },
        {
            "WID": "207",
            "EWord": "Sing",
            "HWord": "गाओ",
            "PWord": "ਗਾਉ",
            "VideoPath": "Sing.mp4"
        },
        {
            "WID": "208",
            "EWord": "Sister-in-Law",
            "HWord": "भाभी",
            "PWord": "ਭਾਬੀ",
            "VideoPath": "Sister-in-Law.mp4"
        },
        {
            "WID": "209",
            "EWord": "Sit",
            "HWord": "बैठना",
            "PWord": "ਬੈਠਣਾ",
            "VideoPath": "Sit.mp4"
        },
        {
            "WID": "210",
            "EWord": "Skin",
            "HWord": "त्वचा",
            "PWord": "ਚਮੜੀ",
            "VideoPath": "Skin.mp4"
        },
        {
            "WID": "211",
            "EWord": "Sky Blue",
            "HWord": "आसमानी नीला",
            "PWord": "ਅਸਮਾਨੀ ਨੀਲਾ",
            "VideoPath": "Sky_Blue.mp4"
        },
        {
            "WID": "212",
            "EWord": "Sleep",
            "HWord": "निद्रा",
            "PWord": "ਨੀਂਦ",
            "VideoPath": "Sleep.mp4"
        },
        {
            "WID": "213",
            "EWord": "Slight",
            "HWord": "थोड़ा",
            "PWord": "ਥੋੜਾ",
            "VideoPath": "Slight.mp4"
        },
        {
            "WID": "214",
            "EWord": "Slowly",
            "HWord": "धीरे",
            "PWord": "ਹੌਲੀ",
            "VideoPath": "Slowly.mp4"
        },
        {
            "WID": "215",
            "EWord": "Smell",
            "HWord": "गंध",
            "PWord": "ਗੰਧ",
            "VideoPath": "Smell.mp4"
        },
        {
            "WID": "216",
            "EWord": "Smile",
            "HWord": "मुस्कान",
            "PWord": "ਮੁਸਕਾਨ",
            "VideoPath": "Smile.mp4"
        },
        {
            "WID": "217",
            "EWord": "Society",
            "HWord": "समाज",
            "PWord": "ਸਮਾਜ",
            "VideoPath": "Society.mp4"
        },
        {
            "WID": "218",
            "EWord": "Son",
            "HWord": "बेटा",
            "PWord": "ਪੁੱਤ",
            "VideoPath": "Son.mp4"
        },
        {
            "WID": "219",
            "EWord": "Sour",
            "HWord": "खट्टा",
            "PWord": "ਖੱਟਾ",
            "VideoPath": "Sour.mp4"
        },
        {
            "WID": "220",
            "EWord": "Speak",
            "HWord": "बोलना",
            "PWord": "ਬੋਲੋ",
            "VideoPath": "Speak.mp4"
        },
        {
            "WID": "221",
            "EWord": "Stand",
            "HWord": "खड़ा होना",
            "PWord": "ਖੜਾ ਹੋਣਾ",
            "VideoPath": "Stand.mp4"
        },
        {
            "WID": "222",
            "EWord": "Star",
            "HWord": "सितारा",
            "PWord": "ਤਾਰਾ",
            "VideoPath": "Star.mp4"
        },
        {
            "WID": "223",
            "EWord": "Sting",
            "HWord": "डंक",
            "PWord": "ਡੰਕ",
            "VideoPath": "Sting.mp4"
        },
        {
            "WID": "224",
            "EWord": "Stomach",
            "HWord": "पेट",
            "PWord": "ਢਿੱਡ",
            "VideoPath": "Stomach.mp4"
        },
        {
            "WID": "225",
            "EWord": "Stop",
            "HWord": "विराम",
            "PWord": "ਵਿਰਾਮ",
            "VideoPath": "Stop.mp4"
        },
        {
            "WID": "226",
            "EWord": "Straight",
            "HWord": "सीधा",
            "PWord": "ਸਿੱਧਾ",
            "VideoPath": "Straight.mp4"
        },
        {
            "WID": "227",
            "EWord": "Strong",
            "HWord": "मजबूत",
            "PWord": "ਮਜ਼ਬੂਤ",
            "VideoPath": "Strong.mp4"
        },
        {
            "WID": "228",
            "EWord": "Student",
            "HWord": "छात्र",
            "PWord": "ਵਿਦਿਆਰਥੀ",
            "VideoPath": "Student.mp4"
        },
        {
            "WID": "229",
            "EWord": "Sun",
            "HWord": "सूर्य",
            "PWord": "ਸੂਰਜ",
            "VideoPath": "Sun.mp4"
        },
        {
            "WID": "230",
            "EWord": "Surname",
            "HWord": "उपनाम",
            "PWord": "ਉਪਨਾਮ",
            "VideoPath": "Surname.mp4"
        },
        {
            "WID": "231",
            "EWord": "Table",
            "HWord": "मेज",
            "PWord": "ਮੇਜ",
            "VideoPath": "Table.mp4"
        },
        {
            "WID": "232",
            "EWord": "Talk",
            "HWord": "बातचीत",
            "PWord": "ਗੱਲ",
            "VideoPath": "Talk.mp4"
        },
        {
            "WID": "233",
            "EWord": "Tape",
            "HWord": "टेप",
            "PWord": "ਚੇਪੀ",
            "VideoPath": "Tape.mp4"
        },
        {
            "WID": "234",
            "EWord": "Teacher",
            "HWord": "अध्यापक",
            "PWord": "ਅਧਿਆਪਕ",
            "VideoPath": "Teacher.mp4"
        },
        {
            "WID": "235",
            "EWord": "Teeth",
            "HWord": "दांत",
            "PWord": "ਦੰਦ",
            "VideoPath": "Teeth.mp4"
        },
        {
            "WID": "236",
            "EWord": "Tell",
            "HWord": "बताना",
            "PWord": "ਦੱਸਣਾ",
            "VideoPath": "Tell.mp4"
        },
        {
            "WID": "237",
            "EWord": "Tense",
            "HWord": "तनाव",
            "PWord": "ਤਣਾਅ",
            "VideoPath": "Tense.mp4"
        },
        {
            "WID": "238",
            "EWord": "Thank You",
            "HWord": "धन्यवाद",
            "PWord": "ਧੰਨਵਾਦ",
            "VideoPath": "Thank_You.mp4"
        },
        {
            "WID": "239",
            "EWord": "Thigh",
            "HWord": "जांघ",
            "PWord": "ਪੱਟ",
            "VideoPath": "Thigh.mp4"
        },
        {
            "WID": "240",
            "EWord": "Think",
            "HWord": "सोच",
            "PWord": "ਸੋਚ",
            "VideoPath": "Think.mp4"
        },
        {
            "WID": "241",
            "EWord": "Throat",
            "HWord": "कंठ",
            "PWord": "ਕੰਠ",
            "VideoPath": "Throat.mp4"
        },
        {
            "WID": "242",
            "EWord": "Tongue",
            "HWord": "जीभ",
            "PWord": "ਜੀਭ",
            "VideoPath": "Tongue.mp4"
        },
        {
            "WID": "243",
            "EWord": "Top",
            "HWord": "शिखर",
            "PWord": "ਸ਼ਿਖਰ",
            "VideoPath": "Top.mp4"
        },
        {
            "WID": "244",
            "EWord": "Touch",
            "HWord": "स्पर्श",
            "PWord": "ਛੋਹ",
            "VideoPath": "Touch.mp4"
        },
        {
            "WID": "245",
            "EWord": "Tough",
            "HWord": "सख़्त",
            "PWord": "ਮੁਸ਼ਕਲ",
            "VideoPath": "Tough.mp4"
        },
        {
            "WID": "246",
            "EWord": "Train",
            "HWord": "रेलगाड़ी",
            "PWord": "ਰੇਲ ਗੱਡੀ",
            "VideoPath": "Train.mp4"
        },
        {
            "WID": "247",
            "EWord": "Trouble",
            "HWord": "मुसीबत",
            "PWord": "ਮੁਸੀਬਤ",
            "VideoPath": "Trouble.mp4"
        },
        {
            "WID": "248",
            "EWord": "Trust",
            "HWord": "विश्वास",
            "PWord": "ਭਰੋਸਾ",
            "VideoPath": "Trust.mp4"
        },
        {
            "WID": "249",
            "EWord": "Twins",
            "HWord": "जुड़वां",
            "PWord": "ਜੌੜੇ",
            "VideoPath": "Twins.mp4"
        },
        {
            "WID": "250",
            "EWord": "Under",
            "HWord": "अंतर्गत",
            "PWord": "ਅਧੀਨ",
            "VideoPath": "Under.mp4"
        },
        {
            "WID": "251",
            "EWord": "Understand",
            "HWord": "समझना",
            "PWord": "ਸਮਝੋ",
            "VideoPath": "Understand.mp4"
        },
        {
            "WID": "252",
            "EWord": "Voice",
            "HWord": "आवाज़",
            "PWord": "ਆਵਾਜ਼",
            "VideoPath": "Voice.mp4"
        },
        {
            "WID": "253",
            "EWord": "Waist",
            "HWord": "कमर",
            "PWord": "ਕਮਰ",
            "VideoPath": "Waist.mp4"
        },
        {
            "WID": "254",
            "EWord": "Walk",
            "HWord": "टहलना\r\n",
            "PWord": "ਸੈਰ",
            "VideoPath": "Walk.mp4"
        },
        {
            "WID": "255",
            "EWord": "Watch",
            "HWord": "देखना",
            "PWord": "ਦੇਖਣਾ",
            "VideoPath": "Watch.mp4"
        },
        {
            "WID": "256",
            "EWord": "Water Bottle",
            "HWord": "पानी की बोतल",
            "PWord": "ਪਾਣੀ ਦੀ ਬੋਤਲ",
            "VideoPath": "Water_Bottle.mp4"
        },
        {
            "WID": "257",
            "EWord": "Weather",
            "HWord": "मौसम",
            "PWord": "ਮੌਸਮ",
            "VideoPath": "Weather.mp4"
        },
        {
            "WID": "258",
            "EWord": "Wedding",
            "HWord": "विवाह",
            "PWord": "ਵਿਆਹ",
            "VideoPath": "Wedding.mp4"
        },
        {
            "WID": "259",
            "EWord": "White",
            "HWord": "सफेद",
            "PWord": "ਚਿੱਟਾ",
            "VideoPath": "White.mp4"
        },
        {
            "WID": "260",
            "EWord": "White Board",
            "HWord": "सफेद बोर्ड",
            "PWord": "ਚਿੱਟਾ ਬੋਰਡ",
            "VideoPath": "White_Board.mp4"
        },
        {
            "WID": "261",
            "EWord": "Wife",
            "HWord": "पत्नी",
            "PWord": "ਪਤਨੀ",
            "VideoPath": "Wife.mp4"
        },
        {
            "WID": "262",
            "EWord": "Wind",
            "HWord": "हवा",
            "PWord": "ਹਵਾ",
            "VideoPath": "Wind.mp4"
        },
        {
            "WID": "263",
            "EWord": "Woman",
            "HWord": "स्त्री",
            "PWord": "ਔਰਤ",
            "VideoPath": "Woman.mp4"
        },
        {
            "WID": "264",
            "EWord": "Word",
            "HWord": "शब्द",
            "PWord": "ਸ਼ਬਦ",
            "VideoPath": "Word.mp4"
        },
        {
            "WID": "265",
            "EWord": "Work",
            "HWord": "काम",
            "PWord": "ਕੰਮ",
            "VideoPath": "Work.mp4"
        },
        {
            "WID": "266",
            "EWord": "Write",
            "HWord": "लिखना",
            "PWord": "ਲਿਖਣਾ ",
            "VideoPath": "Write.mp4"
        },
        {
            "WID": "267",
            "EWord": "Wrong",
            "HWord": "गलत",
            "PWord": "ਗਲਤ",
            "VideoPath": "Wrong.mp4"
        },
        {
            "WID": "268",
            "EWord": "Yawn",
            "HWord": "अंगड़ाई",
            "PWord": "ਹਵਾਉਣਾ",
            "VideoPath": "Yawn.mp4"
        },
        {
            "WID": "269",
            "EWord": "Yellow",
            "HWord": "पीला",
            "PWord": "ਪੀਲਾ",
            "VideoPath": "Yellow.mp4"
        },
        {
            "WID": "270",
            "EWord": "False",
            "HWord": "झूठा",
            "PWord": "ਝੂਠਾ",
            "VideoPath": "FALSE.mp4"
        },
        {
            "WID": "271",
            "EWord": "True",
            "HWord": "सच",
            "PWord": "ਸੱਚ",
            "VideoPath": "TRUE.mp4"
        }
    ]
} 